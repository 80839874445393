/* You can add global styles to this file, and also import other style files */
.auth-wrapper #recoverform,.jvectormap-zoomin,.jvectormap-zoomout,html[dir=rtl] .dz-hidden-input{display:none}
.auth-wrapper{min-height:100vh;position:relative}
.auth-wrapper .auth-box{background:#fff;padding:20px;box-shadow:1px 0 20px rgba(0,0,0,.08);max-width:400px;width:90%;margin:10% 0}
.auth-wrapper .auth-box .logo{text-align:center}
.auth-wrapper .auth-box.on-sidebar{top:0;right:0;height:100%;margin:0;position:absolute}
.auth-wrapper .auth-sidebar{position:fixed;height:100%;right:0;overflow:auto;margin:0;top:0}
@media (max-width:767px){.ct-bar{stroke-width:10px!important}
.auth-wrapper .auth-sidebar{position:relative;max-width:100%;width:100%;margin:40px 0 60px}
.auth-wrapper .demo-text{margin-top:30px}
.email-table{min-width:500px}}