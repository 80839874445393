.white {
  color : #FFFFFF !important;
}

.bg-white {
  background-color : #FFFFFF !important;
}
.bg-white .card-header, .bg-white .card-footer {
  background-color : transparent;
}

.alert-white {
  background : rgba(255, 255, 255, 0.2) !important;
  color : #FFFFFF !important;
}
.alert-white .alert-heading {
  box-shadow : rgba(255, 255, 255, 0.4) 0 6px 15px -7px;
}
.alert-white .alert-link {
  color : #F2F2F2 !important;
}

.bg-rgba-white {
  background : rgba(255, 255, 255, 0.15) !important;
}

.border-white {
  border : 1px solid #FFFFFF !important;
}

.border-top-white {
  border-top : 1px solid #FFFFFF;
}

.border-bottom-white {
  border-bottom : 1px solid #FFFFFF;
}

.border-left-white {
  border-left : 1px solid #FFFFFF;
}

.border-right-white {
  border-right : 1px solid #FFFFFF;
}

.bg-white.badge-glow, .border-white.badge-glow, .badge-white.badge-glow {
  box-shadow : 0 0 10px #FFFFFF;
}

.badge.badge-white {
  background-color : #FFFFFF;
}

.badge.badge-light-white {
  color : #FFFFFF !important;
  font-weight : 500;
}

.overlay-white {
  background : #FFFFFF;
  /* The Fallback */
  background : rgba(255, 255, 255, 0.6);
}

.btn-white {
  border-color : !important;
  background-color : #FFFFFF !important;
  color : #FFFFFF;
}
.btn-white:hover {
  border-color : !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px white;
}
.btn-white:focus, .btn-white:active {
  color : #FFFFFF !important;
}

.btn-flat-white {
  background-color : transparent;
  color : #FFFFFF;
}
.btn-flat-white:hover {
  color : #FFFFFF;
  background-color : rgba(255, 255, 255, 0.1);
}
.btn-flat-white:active, .btn-flat-white:focus {
  background-color : #FFFFFF;
  color : #FFFFFF !important;
}

.btn-relief-white {
  background-color : #FFFFFF;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-white:hover {
  color : #FFFFFF;
}
.btn-relief-white:active, .btn-relief-white:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-white {
  border : 1px solid #FFFFFF;
  background-color : transparent;
  color : #FFFFFF;
}
.btn-outline-white:hover {
  background-color : rgba(255, 255, 255, 0.08);
  color : #FFFFFF;
}
.btn-outline-white:active {
  color : #FFFFFF !important;
}

.btn-white ~ .dropdown-menu .dropdown-item.active, .btn-outline-white ~ .dropdown-menu .dropdown-item.active, .btn-flat-white ~ .dropdown-menu .dropdown-item.active {
  background-color : #FFFFFF;
  color : #FFFFFF;
}
.btn-white ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-white ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-white ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-white ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-white ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-white ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #FFFFFF;
}

.dropdown.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:hover i {
  color : #FFFFFF;
}

.dropdown.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-white ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-white {
  background-color : #FFFFFF;
}

.pagination-white .page-item.active .page-link {
  background : #FFFFFF;
  color : #FFFFFF;
}
.pagination-white .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-white .page-item .page-link:hover {
  color : #FFFFFF;
}

.pagination-white .page-item.prev-item .page-link:hover, .pagination-white .page-item.next-item .page-link:hover {
  background : #FFFFFF;
  color : #FFFFFF;
}

.nav-pill-white .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #FFFFFF !important;
}

.progress-bar-white {
  background-color : rgba(255, 255, 255, 0.1);
}
.progress-bar-white .progress-bar {
  background-color : #FFFFFF;
}

.chip-white {
  background-color : #FFFFFF !important;
}
.chip-white .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-white .divider-text:before, .divider.divider-white .divider-text:after {
  border-color : #FFFFFF !important;
}

input[type='checkbox'].bg-white + .custom-control-label:before, input[type='radio'].bg-white + .custom-control-label:before {
  background-color : #FFFFFF !important;
}

input:focus ~ .bg-white {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFFFFF !important;
}

.vs-checkbox-white input:checked ~ .vs-checkbox {
  border-color : #FFFFFF !important;
}
.vs-checkbox-white input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #FFFFFF !important;
}

.vs-checkbox-white input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #FFFFFF !important;
}

.custom-switch-white .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #FFFFFF !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-white input:checked ~ .vs-radio .vs-radio--circle {
  background : white !important;
  box-shadow : 0 3px 12px 0 rgba(255, 255, 255, 0.4) !important;
}

.black {
  color : #000000 !important;
}

.bg-black {
  background-color : #000000 !important;
}
.bg-black .card-header, .bg-black .card-footer {
  background-color : transparent;
}

.alert-black {
  background : rgba(0, 0, 0, 0.2) !important;
  color : #000000 !important;
}
.alert-black .alert-heading {
  box-shadow : rgba(0, 0, 0, 0.4) 0 6px 15px -7px;
}
.alert-black .alert-link {
  color : black !important;
}

.bg-rgba-black {
  background : rgba(0, 0, 0, 0.15) !important;
}

.border-black {
  border : 1px solid #000000 !important;
}

.border-top-black {
  border-top : 1px solid #000000;
}

.border-bottom-black {
  border-bottom : 1px solid #000000;
}

.border-left-black {
  border-left : 1px solid #000000;
}

.border-right-black {
  border-right : 1px solid #000000;
}

.bg-black.badge-glow, .border-black.badge-glow, .badge-black.badge-glow {
  box-shadow : 0 0 10px #000000;
}

.badge.badge-black {
  background-color : #000000;
}

.badge.badge-light-black {
  color : #000000 !important;
  font-weight : 500;
}

.overlay-black {
  background : #000000;
  /* The Fallback */
  background : rgba(0, 0, 0, 0.6);
}

.btn-black {
  border-color : !important;
  background-color : #000000 !important;
  color : #FFFFFF;
}
.btn-black:hover {
  border-color : !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px black;
}
.btn-black:focus, .btn-black:active {
  color : #FFFFFF !important;
}

.btn-flat-black {
  background-color : transparent;
  color : #000000;
}
.btn-flat-black:hover {
  color : #000000;
  background-color : rgba(0, 0, 0, 0.1);
}
.btn-flat-black:active, .btn-flat-black:focus {
  background-color : #000000;
  color : #FFFFFF !important;
}

.btn-relief-black {
  background-color : #000000;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-black:hover {
  color : #FFFFFF;
}
.btn-relief-black:active, .btn-relief-black:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-black {
  border : 1px solid #000000;
  background-color : transparent;
  color : #000000;
}
.btn-outline-black:hover {
  background-color : rgba(0, 0, 0, 0.08);
  color : #000000;
}
.btn-outline-black:active {
  color : #FFFFFF !important;
}

.btn-black ~ .dropdown-menu .dropdown-item.active, .btn-outline-black ~ .dropdown-menu .dropdown-item.active, .btn-flat-black ~ .dropdown-menu .dropdown-item.active {
  background-color : #000000;
  color : #FFFFFF;
}
.btn-black ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-black ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-black ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-black ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-black ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-black ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #000000;
}

.dropdown.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:hover i {
  color : #000000;
}

.dropdown.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-black ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-black {
  background-color : #000000;
}

.pagination-black .page-item.active .page-link {
  background : #000000;
  color : #FFFFFF;
}
.pagination-black .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-black .page-item .page-link:hover {
  color : #000000;
}

.pagination-black .page-item.prev-item .page-link:hover, .pagination-black .page-item.next-item .page-link:hover {
  background : #000000;
  color : #FFFFFF;
}

.nav-pill-black .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #000000 !important;
}

.progress-bar-black {
  background-color : rgba(0, 0, 0, 0.1);
}
.progress-bar-black .progress-bar {
  background-color : #000000;
}

.chip-black {
  background-color : #000000 !important;
}
.chip-black .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-black .divider-text:before, .divider.divider-black .divider-text:after {
  border-color : #000000 !important;
}

input[type='checkbox'].bg-black + .custom-control-label:before, input[type='radio'].bg-black + .custom-control-label:before {
  background-color : #000000 !important;
}

input:focus ~ .bg-black {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #000000 !important;
}

.vs-checkbox-black input:checked ~ .vs-checkbox {
  border-color : #000000 !important;
}
.vs-checkbox-black input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #000000 !important;
}

.vs-checkbox-black input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #000000 !important;
}

.custom-switch-black .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #000000 !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-black input:checked ~ .vs-radio .vs-radio--circle {
  background : black !important;
  box-shadow : 0 3px 12px 0 rgba(0, 0, 0, 0.4) !important;
}

.dark {
  color : #1E1E1E !important;
}

.bg-dark {
  background-color : #1E1E1E !important;
}
.bg-dark .card-header, .bg-dark .card-footer {
  background-color : transparent;
}

.alert-dark {
  background : rgba(30, 30, 30, 0.2) !important;
  color : #1E1E1E !important;
}
.alert-dark .alert-heading {
  box-shadow : rgba(30, 30, 30, 0.4) 0 6px 15px -7px;
}
.alert-dark .alert-link {
  color : #111111 !important;
}

.bg-rgba-dark {
  background : rgba(30, 30, 30, 0.15) !important;
}

.border-dark {
  border : 1px solid #1E1E1E !important;
}

.border-top-dark {
  border-top : 1px solid #1E1E1E;
}

.border-bottom-dark {
  border-bottom : 1px solid #1E1E1E;
}

.border-left-dark {
  border-left : 1px solid #1E1E1E;
}

.border-right-dark {
  border-right : 1px solid #1E1E1E;
}

.bg-dark.badge-glow, .border-dark.badge-glow, .badge-dark.badge-glow {
  box-shadow : 0 0 10px #1E1E1E;
}

.badge.badge-dark {
  background-color : #1E1E1E;
}

.badge.badge-light-dark {
  color : #1E1E1E !important;
  font-weight : 500;
}

.overlay-dark {
  background : #1E1E1E;
  /* The Fallback */
  background : rgba(30, 30, 30, 0.6);
}

.btn-dark {
  border-color : !important;
  background-color : #1E1E1E !important;
  color : #FFFFFF;
}
.btn-dark:hover {
  border-color : !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #1E1E1E;
}
.btn-dark:focus, .btn-dark:active {
  color : #FFFFFF !important;
}

.btn-flat-dark {
  background-color : transparent;
  color : #1E1E1E;
}
.btn-flat-dark:hover {
  color : #1E1E1E;
  background-color : rgba(30, 30, 30, 0.1);
}
.btn-flat-dark:active, .btn-flat-dark:focus {
  background-color : #1E1E1E;
  color : #FFFFFF !important;
}

.btn-relief-dark {
  background-color : #1E1E1E;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-dark:hover {
  color : #FFFFFF;
}
.btn-relief-dark:active, .btn-relief-dark:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-dark {
  border : 1px solid #1E1E1E;
  background-color : transparent;
  color : #1E1E1E;
}
.btn-outline-dark:hover {
  background-color : rgba(30, 30, 30, 0.08);
  color : #1E1E1E;
}
.btn-outline-dark:active {
  color : #FFFFFF !important;
}

.btn-dark ~ .dropdown-menu .dropdown-item.active, .btn-outline-dark ~ .dropdown-menu .dropdown-item.active, .btn-flat-dark ~ .dropdown-menu .dropdown-item.active {
  background-color : #1E1E1E;
  color : #FFFFFF;
}
.btn-dark ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-dark ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-dark ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-dark ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-dark ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-dark ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #1E1E1E;
}

.dropdown.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:hover i {
  color : #1E1E1E;
}

.dropdown.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-dark ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-dark {
  background-color : #1E1E1E;
}

.pagination-dark .page-item.active .page-link {
  background : #1E1E1E;
  color : #FFFFFF;
}
.pagination-dark .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-dark .page-item .page-link:hover {
  color : #1E1E1E;
}

.pagination-dark .page-item.prev-item .page-link:hover, .pagination-dark .page-item.next-item .page-link:hover {
  background : #1E1E1E;
  color : #FFFFFF;
}

.nav-pill-dark .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #1E1E1E !important;
}

.progress-bar-dark {
  background-color : rgba(30, 30, 30, 0.1);
}
.progress-bar-dark .progress-bar {
  background-color : #1E1E1E;
}

.chip-dark {
  background-color : #1E1E1E !important;
}
.chip-dark .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-dark .divider-text:before, .divider.divider-dark .divider-text:after {
  border-color : #1E1E1E !important;
}

input[type='checkbox'].bg-dark + .custom-control-label:before, input[type='radio'].bg-dark + .custom-control-label:before {
  background-color : #1E1E1E !important;
}

input:focus ~ .bg-dark {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #1E1E1E !important;
}

.vs-checkbox-dark input:checked ~ .vs-checkbox {
  border-color : #1E1E1E !important;
}
.vs-checkbox-dark input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #1E1E1E !important;
}

.vs-checkbox-dark input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #1E1E1E !important;
}

.custom-switch-dark .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #1E1E1E !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-dark input:checked ~ .vs-radio .vs-radio--circle {
  background : #1E1E1E !important;
  box-shadow : 0 3px 12px 0 rgba(30, 30, 30, 0.4) !important;
}

.dark.gradient-bg {
  color : #640064 !important;
}

.bg-dark.bg-gradient-bg {
  background-color : #640064 !important;
}

.btn-dark.btn-gradient-bg {
  border-color : !important;
  background-color : #640064 !important;
}
.btn-dark.btn-gradient-bg:hover {
  border-color : !important;
  background-color : !important;
}
.btn-dark.btn-gradient-bg:focus, .btn-dark.btn-gradient-bg:active {
  border-color : !important;
  background-color : !important;
}

.btn-outline-dark.btn-outline-gradient-bg {
  border-color : #640064 !important;
  color : #640064 !important;
}
.btn-outline-dark.btn-outline-gradient-bg:hover {
  background-color : #640064 !important;
}

input:focus ~ .bg-dark {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #640064 !important;
}

.border-dark.border-gradient-bg {
  border : 1px solid #640064 !important;
}

.border-top-dark.border-top-gradient-bg {
  border-top : 1px solid #640064 !important;
}

.border-bottom-dark.border-bottom-gradient-bg {
  border-bottom : 1px solid #640064 !important;
}

.border-left-dark.border-left-gradient-bg {
  border-left : 1px solid #640064 !important;
}

.border-right-dark.border-right-gradient-bg {
  border-right : 1px solid #640064 !important;
}

.overlay-dark.overlay-gradient-bg {
  background : #640064;
  /* The Fallback */
  background : rgba(100, 0, 100, 0.6);
}

.light {
  color : #BABFC7 !important;
}

.bg-light {
  background-color : #BABFC7 !important;
}
.bg-light .card-header, .bg-light .card-footer {
  background-color : transparent;
}

.alert-light {
  background : rgba(186, 191, 199, 0.2) !important;
  color : #BABFC7 !important;
}
.alert-light .alert-heading {
  box-shadow : rgba(186, 191, 199, 0.4) 0 6px 15px -7px;
}
.alert-light .alert-link {
  color : #ACB2BC !important;
}

.bg-rgba-light {
  background : rgba(186, 191, 199, 0.15) !important;
}

.border-light {
  border : 1px solid #BABFC7 !important;
}

.border-top-light {
  border-top : 1px solid #BABFC7;
}

.border-bottom-light {
  border-bottom : 1px solid #BABFC7;
}

.border-left-light {
  border-left : 1px solid #BABFC7;
}

.border-right-light {
  border-right : 1px solid #BABFC7;
}

.bg-light.badge-glow, .border-light.badge-glow, .badge-light.badge-glow {
  box-shadow : 0 0 10px #BABFC7;
}

.badge.badge-light {
  background-color : #BABFC7;
}

.badge.badge-light-light {
  color : #BABFC7 !important;
  font-weight : 500;
}

.overlay-light {
  background : #BABFC7;
  /* The Fallback */
  background : rgba(186, 191, 199, 0.6);
}

.btn-light {
  border-color : !important;
  background-color : #BABFC7 !important;
  color : #FFFFFF;
}
.btn-light:hover {
  border-color : !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #BABFC7;
}
.btn-light:focus, .btn-light:active {
  color : #FFFFFF !important;
}

.btn-flat-light {
  background-color : transparent;
  color : #BABFC7;
}
.btn-flat-light:hover {
  color : #BABFC7;
  background-color : rgba(186, 191, 199, 0.1);
}
.btn-flat-light:active, .btn-flat-light:focus {
  background-color : #BABFC7;
  color : #FFFFFF !important;
}

.btn-relief-light {
  background-color : #BABFC7;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-light:hover {
  color : #FFFFFF;
}
.btn-relief-light:active, .btn-relief-light:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-light {
  border : 1px solid #BABFC7;
  background-color : transparent;
  color : #BABFC7;
}
.btn-outline-light:hover {
  background-color : rgba(186, 191, 199, 0.08);
  color : #BABFC7;
}
.btn-outline-light:active {
  color : #FFFFFF !important;
}

.btn-light ~ .dropdown-menu .dropdown-item.active, .btn-outline-light ~ .dropdown-menu .dropdown-item.active, .btn-flat-light ~ .dropdown-menu .dropdown-item.active {
  background-color : #BABFC7;
  color : #FFFFFF;
}
.btn-light ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-light ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-light ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-light ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-light ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-light ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #BABFC7;
}

.dropdown.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:hover i {
  color : #BABFC7;
}

.dropdown.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-light ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-light {
  background-color : #BABFC7;
}

.pagination-light .page-item.active .page-link {
  background : #BABFC7;
  color : #FFFFFF;
}
.pagination-light .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-light .page-item .page-link:hover {
  color : #BABFC7;
}

.pagination-light .page-item.prev-item .page-link:hover, .pagination-light .page-item.next-item .page-link:hover {
  background : #BABFC7;
  color : #FFFFFF;
}

.nav-pill-light .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #BABFC7 !important;
}

.progress-bar-light {
  background-color : rgba(186, 191, 199, 0.1);
}
.progress-bar-light .progress-bar {
  background-color : #BABFC7;
}

.chip-light {
  background-color : #BABFC7 !important;
}
.chip-light .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-light .divider-text:before, .divider.divider-light .divider-text:after {
  border-color : #BABFC7 !important;
}

input[type='checkbox'].bg-light + .custom-control-label:before, input[type='radio'].bg-light + .custom-control-label:before {
  background-color : #BABFC7 !important;
}

input:focus ~ .bg-light {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #BABFC7 !important;
}

.vs-checkbox-light input:checked ~ .vs-checkbox {
  border-color : #BABFC7 !important;
}
.vs-checkbox-light input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #BABFC7 !important;
}

.vs-checkbox-light input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #BABFC7 !important;
}

.custom-switch-light .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #BABFC7 !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-light input:checked ~ .vs-radio .vs-radio--circle {
  background : #BABFC7 !important;
  box-shadow : 0 3px 12px 0 rgba(186, 191, 199, 0.4) !important;
}

.light.gradient-bg {
  color : #640064 !important;
}

.bg-light.bg-gradient-bg {
  background-color : #640064 !important;
}

.btn-light.btn-gradient-bg {
  border-color : !important;
  background-color : #640064 !important;
}
.btn-light.btn-gradient-bg:hover {
  border-color : !important;
  background-color : !important;
}
.btn-light.btn-gradient-bg:focus, .btn-light.btn-gradient-bg:active {
  border-color : !important;
  background-color : !important;
}

.btn-outline-light.btn-outline-gradient-bg {
  border-color : #640064 !important;
  color : #640064 !important;
}
.btn-outline-light.btn-outline-gradient-bg:hover {
  background-color : #640064 !important;
}

input:focus ~ .bg-light {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #640064 !important;
}

.border-light.border-gradient-bg {
  border : 1px solid #640064 !important;
}

.border-top-light.border-top-gradient-bg {
  border-top : 1px solid #640064 !important;
}

.border-bottom-light.border-bottom-gradient-bg {
  border-bottom : 1px solid #640064 !important;
}

.border-left-light.border-left-gradient-bg {
  border-left : 1px solid #640064 !important;
}

.border-right-light.border-right-gradient-bg {
  border-right : 1px solid #640064 !important;
}

.overlay-light.overlay-gradient-bg {
  background : #640064;
  /* The Fallback */
  background : rgba(100, 0, 100, 0.6);
}

.primary.gradient-light {
  color : #CE9FFC !important;
}

.bg-primary.bg-gradient-light {
  background-color : #CE9FFC !important;
}

.btn-primary.btn-gradient-light {
  border-color : #4839EB !important;
  background-color : #CE9FFC !important;
}
.btn-primary.btn-gradient-light:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-gradient-light:focus, .btn-primary.btn-gradient-light:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-gradient-light {
  border-color : #CE9FFC !important;
  color : #CE9FFC !important;
}
.btn-outline-primary.btn-outline-gradient-light:hover {
  background-color : #CE9FFC !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #CE9FFC !important;
}

.border-primary.border-gradient-light {
  border : 1px solid #CE9FFC !important;
}

.border-top-primary.border-top-gradient-light {
  border-top : 1px solid #CE9FFC !important;
}

.border-bottom-primary.border-bottom-gradient-light {
  border-bottom : 1px solid #CE9FFC !important;
}

.border-left-primary.border-left-gradient-light {
  border-left : 1px solid #CE9FFC !important;
}

.border-right-primary.border-right-gradient-light {
  border-right : 1px solid #CE9FFC !important;
}

.overlay-primary.overlay-gradient-light {
  background : #CE9FFC;
  /* The Fallback */
  background : rgba(206, 159, 252, 0.6);
}

.primary.light-badge {
  color : #DEDBFB !important;
}

.bg-primary.bg-light-badge {
  background-color : #DEDBFB !important;
}

.btn-primary.btn-light-badge {
  border-color : #4839EB !important;
  background-color : #DEDBFB !important;
}
.btn-primary.btn-light-badge:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-light-badge:focus, .btn-primary.btn-light-badge:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-light-badge {
  border-color : #DEDBFB !important;
  color : #DEDBFB !important;
}
.btn-outline-primary.btn-outline-light-badge:hover {
  background-color : #DEDBFB !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #DEDBFB !important;
}

.border-primary.border-light-badge {
  border : 1px solid #DEDBFB !important;
}

.border-top-primary.border-top-light-badge {
  border-top : 1px solid #DEDBFB !important;
}

.border-bottom-primary.border-bottom-light-badge {
  border-bottom : 1px solid #DEDBFB !important;
}

.border-left-primary.border-left-light-badge {
  border-left : 1px solid #DEDBFB !important;
}

.border-right-primary.border-right-light-badge {
  border-right : 1px solid #DEDBFB !important;
}

.overlay-primary.overlay-light-badge {
  background : #DEDBFB;
  /* The Fallback */
  background : rgba(222, 219, 251, 0.6);
}

.primary.lighten-5 {
  color : #DEDBFB !important;
}

.bg-primary.bg-lighten-5 {
  background-color : #DEDBFB !important;
}

.btn-primary.btn-lighten-5 {
  border-color : #4839EB !important;
  background-color : #DEDBFB !important;
}
.btn-primary.btn-lighten-5:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-lighten-5:focus, .btn-primary.btn-lighten-5:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-lighten-5 {
  border-color : #DEDBFB !important;
  color : #DEDBFB !important;
}
.btn-outline-primary.btn-outline-lighten-5:hover {
  background-color : #DEDBFB !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #DEDBFB !important;
}

.border-primary.border-lighten-5 {
  border : 1px solid #DEDBFB !important;
}

.border-top-primary.border-top-lighten-5 {
  border-top : 1px solid #DEDBFB !important;
}

.border-bottom-primary.border-bottom-lighten-5 {
  border-bottom : 1px solid #DEDBFB !important;
}

.border-left-primary.border-left-lighten-5 {
  border-left : 1px solid #DEDBFB !important;
}

.border-right-primary.border-right-lighten-5 {
  border-right : 1px solid #DEDBFB !important;
}

.overlay-primary.overlay-lighten-5 {
  background : #DEDBFB;
  /* The Fallback */
  background : rgba(222, 219, 251, 0.6);
}

.primary.lighten-4 {
  color : #C9C4F9 !important;
}

.bg-primary.bg-lighten-4 {
  background-color : #C9C4F9 !important;
}

.btn-primary.btn-lighten-4 {
  border-color : #4839EB !important;
  background-color : #C9C4F9 !important;
}
.btn-primary.btn-lighten-4:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-lighten-4:focus, .btn-primary.btn-lighten-4:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-lighten-4 {
  border-color : #C9C4F9 !important;
  color : #C9C4F9 !important;
}
.btn-outline-primary.btn-outline-lighten-4:hover {
  background-color : #C9C4F9 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #C9C4F9 !important;
}

.border-primary.border-lighten-4 {
  border : 1px solid #C9C4F9 !important;
}

.border-top-primary.border-top-lighten-4 {
  border-top : 1px solid #C9C4F9 !important;
}

.border-bottom-primary.border-bottom-lighten-4 {
  border-bottom : 1px solid #C9C4F9 !important;
}

.border-left-primary.border-left-lighten-4 {
  border-left : 1px solid #C9C4F9 !important;
}

.border-right-primary.border-right-lighten-4 {
  border-right : 1px solid #C9C4F9 !important;
}

.overlay-primary.overlay-lighten-4 {
  background : #C9C4F9;
  /* The Fallback */
  background : rgba(201, 196, 249, 0.6);
}

.primary.lighten-3 {
  color : #B3ADF7 !important;
}

.bg-primary.bg-lighten-3 {
  background-color : #B3ADF7 !important;
}

.btn-primary.btn-lighten-3 {
  border-color : #4839EB !important;
  background-color : #B3ADF7 !important;
}
.btn-primary.btn-lighten-3:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-lighten-3:focus, .btn-primary.btn-lighten-3:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-lighten-3 {
  border-color : #B3ADF7 !important;
  color : #B3ADF7 !important;
}
.btn-outline-primary.btn-outline-lighten-3:hover {
  background-color : #B3ADF7 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #B3ADF7 !important;
}

.border-primary.border-lighten-3 {
  border : 1px solid #B3ADF7 !important;
}

.border-top-primary.border-top-lighten-3 {
  border-top : 1px solid #B3ADF7 !important;
}

.border-bottom-primary.border-bottom-lighten-3 {
  border-bottom : 1px solid #B3ADF7 !important;
}

.border-left-primary.border-left-lighten-3 {
  border-left : 1px solid #B3ADF7 !important;
}

.border-right-primary.border-right-lighten-3 {
  border-right : 1px solid #B3ADF7 !important;
}

.overlay-primary.overlay-lighten-3 {
  background : #B3ADF7;
  /* The Fallback */
  background : rgba(179, 173, 247, 0.6);
}

.primary.lighten-2 {
  color : #9E95F5 !important;
}

.bg-primary.bg-lighten-2 {
  background-color : #9E95F5 !important;
}

.btn-primary.btn-lighten-2 {
  border-color : #4839EB !important;
  background-color : #9E95F5 !important;
}
.btn-primary.btn-lighten-2:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-lighten-2:focus, .btn-primary.btn-lighten-2:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-lighten-2 {
  border-color : #9E95F5 !important;
  color : #9E95F5 !important;
}
.btn-outline-primary.btn-outline-lighten-2:hover {
  background-color : #9E95F5 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #9E95F5 !important;
}

.border-primary.border-lighten-2 {
  border : 1px solid #9E95F5 !important;
}

.border-top-primary.border-top-lighten-2 {
  border-top : 1px solid #9E95F5 !important;
}

.border-bottom-primary.border-bottom-lighten-2 {
  border-bottom : 1px solid #9E95F5 !important;
}

.border-left-primary.border-left-lighten-2 {
  border-left : 1px solid #9E95F5 !important;
}

.border-right-primary.border-right-lighten-2 {
  border-right : 1px solid #9E95F5 !important;
}

.overlay-primary.overlay-lighten-2 {
  background : #9E95F5;
  /* The Fallback */
  background : rgba(158, 149, 245, 0.6);
}

.primary.lighten-1 {
  color : #887EF2 !important;
}

.bg-primary.bg-lighten-1 {
  background-color : #887EF2 !important;
}

.btn-primary.btn-lighten-1 {
  border-color : #4839EB !important;
  background-color : #887EF2 !important;
}
.btn-primary.btn-lighten-1:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-lighten-1:focus, .btn-primary.btn-lighten-1:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-lighten-1 {
  border-color : #887EF2 !important;
  color : #887EF2 !important;
}
.btn-outline-primary.btn-outline-lighten-1:hover {
  background-color : #887EF2 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #887EF2 !important;
}

.border-primary.border-lighten-1 {
  border : 1px solid #887EF2 !important;
}

.border-top-primary.border-top-lighten-1 {
  border-top : 1px solid #887EF2 !important;
}

.border-bottom-primary.border-bottom-lighten-1 {
  border-bottom : 1px solid #887EF2 !important;
}

.border-left-primary.border-left-lighten-1 {
  border-left : 1px solid #887EF2 !important;
}

.border-right-primary.border-right-lighten-1 {
  border-right : 1px solid #887EF2 !important;
}

.overlay-primary.overlay-lighten-1 {
  background : #887EF2;
  /* The Fallback */
  background : rgba(136, 126, 242, 0.6);
}

.primary {
  color : #7367F0 !important;
}

.bg-primary {
  background-color : #7367F0 !important;
}
.bg-primary .card-header, .bg-primary .card-footer {
  background-color : transparent;
}

.alert-primary {
  background : rgba(115, 103, 240, 0.2) !important;
  color : #7367F0 !important;
}
.alert-primary .alert-heading {
  box-shadow : rgba(115, 103, 240, 0.4) 0 6px 15px -7px;
}
.alert-primary .alert-link {
  color : #5E50EE !important;
}

.bg-rgba-primary {
  background : rgba(115, 103, 240, 0.15) !important;
}

.border-primary {
  border : 1px solid #7367F0 !important;
}

.border-top-primary {
  border-top : 1px solid #7367F0;
}

.border-bottom-primary {
  border-bottom : 1px solid #7367F0;
}

.border-left-primary {
  border-left : 1px solid #7367F0;
}

.border-right-primary {
  border-right : 1px solid #7367F0;
}

.bg-primary.badge-glow, .border-primary.badge-glow, .badge-primary.badge-glow {
  box-shadow : 0 0 10px #7367F0;
}

.badge.badge-primary {
  background-color : #7367F0;
}

.badge.badge-light-primary {
  background-color : #DEDBFB;
  color : #7367F0 !important;
  font-weight : 500;
}

.overlay-primary {
  background : #7367F0;
  /* The Fallback */
  background : rgba(115, 103, 240, 0.6);
}

.btn-primary {
  border-color : #4839EB !important;
  background-color : #7367F0 !important;
  color : #FFFFFF;
}
.btn-primary:hover {
  border-color : #5E50EE !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #7367F0;
}
.btn-primary:focus, .btn-primary:active {
  color : #FFFFFF !important;
}

.btn-flat-primary {
  background-color : transparent;
  color : #7367F0;
}
.btn-flat-primary:hover {
  color : #7367F0;
  background-color : rgba(115, 103, 240, 0.1);
}
.btn-flat-primary:active, .btn-flat-primary:focus {
  background-color : #7367F0;
  color : #FFFFFF !important;
}

.btn-relief-primary {
  background-color : #7367F0;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-primary:hover {
  color : #FFFFFF;
}
.btn-relief-primary:active, .btn-relief-primary:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-primary {
  border : 1px solid #7367F0;
  background-color : transparent;
  color : #7367F0;
}
.btn-outline-primary:hover {
  background-color : rgba(115, 103, 240, 0.08);
  color : #7367F0;
}
.btn-outline-primary:active {
  color : #FFFFFF !important;
}

.btn-primary ~ .dropdown-menu .dropdown-item.active, .btn-outline-primary ~ .dropdown-menu .dropdown-item.active, .btn-flat-primary ~ .dropdown-menu .dropdown-item.active {
  background-color : #7367F0;
  color : #FFFFFF;
}
.btn-primary ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-primary ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-primary ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-primary ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-primary ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-primary ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #7367F0;
}

.dropdown.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:hover i {
  color : #7367F0;
}

.dropdown.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-primary ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-primary {
  background-color : #7367F0;
}

.pagination-primary .page-item.active .page-link {
  background : #7367F0;
  color : #FFFFFF;
}
.pagination-primary .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-primary .page-item .page-link:hover {
  color : #7367F0;
}

.pagination-primary .page-item.prev-item .page-link:hover, .pagination-primary .page-item.next-item .page-link:hover {
  background : #7367F0;
  color : #FFFFFF;
}

.nav-pill-primary .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #7367F0 !important;
}

.progress-bar-primary {
  background-color : rgba(115, 103, 240, 0.1);
}
.progress-bar-primary .progress-bar {
  background-color : #7367F0;
}

.chip-primary {
  background-color : #7367F0 !important;
}
.chip-primary .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
  border-color : #7367F0 !important;
}

input[type='checkbox'].bg-primary + .custom-control-label:before, input[type='radio'].bg-primary + .custom-control-label:before {
  background-color : #7367F0 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #7367F0 !important;
}

.vs-checkbox-primary input:checked ~ .vs-checkbox {
  border-color : #7367F0 !important;
}
.vs-checkbox-primary input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #7367F0 !important;
}

.vs-checkbox-primary input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #7367F0 !important;
}

.custom-switch-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #7367F0 !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-primary input:checked ~ .vs-radio .vs-radio--circle {
  background : #7367F0 !important;
  box-shadow : 0 3px 12px 0 rgba(115, 103, 240, 0.4) !important;
}

.primary.darken-1 {
  color : #5E50EE !important;
}

.bg-primary.bg-darken-1 {
  background-color : #5E50EE !important;
}

.btn-primary.btn-darken-1 {
  border-color : #4839EB !important;
  background-color : #5E50EE !important;
}
.btn-primary.btn-darken-1:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-darken-1:focus, .btn-primary.btn-darken-1:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-darken-1 {
  border-color : #5E50EE !important;
  color : #5E50EE !important;
}
.btn-outline-primary.btn-outline-darken-1:hover {
  background-color : #5E50EE !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #5E50EE !important;
}

.border-primary.border-darken-1 {
  border : 1px solid #5E50EE !important;
}

.border-top-primary.border-top-darken-1 {
  border-top : 1px solid #5E50EE !important;
}

.border-bottom-primary.border-bottom-darken-1 {
  border-bottom : 1px solid #5E50EE !important;
}

.border-left-primary.border-left-darken-1 {
  border-left : 1px solid #5E50EE !important;
}

.border-right-primary.border-right-darken-1 {
  border-right : 1px solid #5E50EE !important;
}

.overlay-primary.overlay-darken-1 {
  background : #5E50EE;
  /* The Fallback */
  background : rgba(94, 80, 238, 0.6);
}

.primary.darken-2 {
  color : #4839EB !important;
}

.bg-primary.bg-darken-2 {
  background-color : #4839EB !important;
}

.btn-primary.btn-darken-2 {
  border-color : #4839EB !important;
  background-color : #4839EB !important;
}
.btn-primary.btn-darken-2:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-darken-2:focus, .btn-primary.btn-darken-2:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-darken-2 {
  border-color : #4839EB !important;
  color : #4839EB !important;
}
.btn-outline-primary.btn-outline-darken-2:hover {
  background-color : #4839EB !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #4839EB !important;
}

.border-primary.border-darken-2 {
  border : 1px solid #4839EB !important;
}

.border-top-primary.border-top-darken-2 {
  border-top : 1px solid #4839EB !important;
}

.border-bottom-primary.border-bottom-darken-2 {
  border-bottom : 1px solid #4839EB !important;
}

.border-left-primary.border-left-darken-2 {
  border-left : 1px solid #4839EB !important;
}

.border-right-primary.border-right-darken-2 {
  border-right : 1px solid #4839EB !important;
}

.overlay-primary.overlay-darken-2 {
  background : #4839EB;
  /* The Fallback */
  background : rgba(72, 57, 235, 0.6);
}

.primary.darken-3 {
  color : #3321E9 !important;
}

.bg-primary.bg-darken-3 {
  background-color : #3321E9 !important;
}

.btn-primary.btn-darken-3 {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-darken-3:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-darken-3:focus, .btn-primary.btn-darken-3:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-darken-3 {
  border-color : #3321E9 !important;
  color : #3321E9 !important;
}
.btn-outline-primary.btn-outline-darken-3:hover {
  background-color : #3321E9 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #3321E9 !important;
}

.border-primary.border-darken-3 {
  border : 1px solid #3321E9 !important;
}

.border-top-primary.border-top-darken-3 {
  border-top : 1px solid #3321E9 !important;
}

.border-bottom-primary.border-bottom-darken-3 {
  border-bottom : 1px solid #3321E9 !important;
}

.border-left-primary.border-left-darken-3 {
  border-left : 1px solid #3321E9 !important;
}

.border-right-primary.border-right-darken-3 {
  border-right : 1px solid #3321E9 !important;
}

.overlay-primary.overlay-darken-3 {
  background : #3321E9;
  /* The Fallback */
  background : rgba(51, 33, 233, 0.6);
}

.primary.darken-4 {
  color : #2716DB !important;
}

.bg-primary.bg-darken-4 {
  background-color : #2716DB !important;
}

.btn-primary.btn-darken-4 {
  border-color : #4839EB !important;
  background-color : #2716DB !important;
}
.btn-primary.btn-darken-4:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-darken-4:focus, .btn-primary.btn-darken-4:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-darken-4 {
  border-color : #2716DB !important;
  color : #2716DB !important;
}
.btn-outline-primary.btn-outline-darken-4:hover {
  background-color : #2716DB !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #2716DB !important;
}

.border-primary.border-darken-4 {
  border : 1px solid #2716DB !important;
}

.border-top-primary.border-top-darken-4 {
  border-top : 1px solid #2716DB !important;
}

.border-bottom-primary.border-bottom-darken-4 {
  border-bottom : 1px solid #2716DB !important;
}

.border-left-primary.border-left-darken-4 {
  border-left : 1px solid #2716DB !important;
}

.border-right-primary.border-right-darken-4 {
  border-right : 1px solid #2716DB !important;
}

.overlay-primary.overlay-darken-4 {
  background : #2716DB;
  /* The Fallback */
  background : rgba(39, 22, 219, 0.6);
}

.primary.accent-1 {
  color : #BDFDFF !important;
}

.bg-primary.bg-accent-1 {
  background-color : #BDFDFF !important;
}

.btn-primary.btn-accent-1 {
  border-color : #4839EB !important;
  background-color : #BDFDFF !important;
}
.btn-primary.btn-accent-1:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-accent-1:focus, .btn-primary.btn-accent-1:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-accent-1 {
  border-color : #BDFDFF !important;
  color : #BDFDFF !important;
}
.btn-outline-primary.btn-outline-accent-1:hover {
  background-color : #BDFDFF !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #BDFDFF !important;
}

.border-primary.border-accent-1 {
  border : 1px solid #BDFDFF !important;
}

.border-top-primary.border-top-accent-1 {
  border-top : 1px solid #BDFDFF !important;
}

.border-bottom-primary.border-bottom-accent-1 {
  border-bottom : 1px solid #BDFDFF !important;
}

.border-left-primary.border-left-accent-1 {
  border-left : 1px solid #BDFDFF !important;
}

.border-right-primary.border-right-accent-1 {
  border-right : 1px solid #BDFDFF !important;
}

.overlay-primary.overlay-accent-1 {
  background : #BDFDFF;
  /* The Fallback */
  background : rgba(189, 253, 255, 0.6);
}

.primary.accent-2 {
  color : #8AFBFF !important;
}

.bg-primary.bg-accent-2 {
  background-color : #8AFBFF !important;
}

.btn-primary.btn-accent-2 {
  border-color : #4839EB !important;
  background-color : #8AFBFF !important;
}
.btn-primary.btn-accent-2:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-accent-2:focus, .btn-primary.btn-accent-2:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-accent-2 {
  border-color : #8AFBFF !important;
  color : #8AFBFF !important;
}
.btn-outline-primary.btn-outline-accent-2:hover {
  background-color : #8AFBFF !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #8AFBFF !important;
}

.border-primary.border-accent-2 {
  border : 1px solid #8AFBFF !important;
}

.border-top-primary.border-top-accent-2 {
  border-top : 1px solid #8AFBFF !important;
}

.border-bottom-primary.border-bottom-accent-2 {
  border-bottom : 1px solid #8AFBFF !important;
}

.border-left-primary.border-left-accent-2 {
  border-left : 1px solid #8AFBFF !important;
}

.border-right-primary.border-right-accent-2 {
  border-right : 1px solid #8AFBFF !important;
}

.overlay-primary.overlay-accent-2 {
  background : #8AFBFF;
  /* The Fallback */
  background : rgba(138, 251, 255, 0.6);
}

.primary.accent-3 {
  color : #57FAFF !important;
}

.bg-primary.bg-accent-3 {
  background-color : #57FAFF !important;
}

.btn-primary.btn-accent-3 {
  border-color : #4839EB !important;
  background-color : #57FAFF !important;
}
.btn-primary.btn-accent-3:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-accent-3:focus, .btn-primary.btn-accent-3:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-accent-3 {
  border-color : #57FAFF !important;
  color : #57FAFF !important;
}
.btn-outline-primary.btn-outline-accent-3:hover {
  background-color : #57FAFF !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #57FAFF !important;
}

.border-primary.border-accent-3 {
  border : 1px solid #57FAFF !important;
}

.border-top-primary.border-top-accent-3 {
  border-top : 1px solid #57FAFF !important;
}

.border-bottom-primary.border-bottom-accent-3 {
  border-bottom : 1px solid #57FAFF !important;
}

.border-left-primary.border-left-accent-3 {
  border-left : 1px solid #57FAFF !important;
}

.border-right-primary.border-right-accent-3 {
  border-right : 1px solid #57FAFF !important;
}

.overlay-primary.overlay-accent-3 {
  background : #57FAFF;
  /* The Fallback */
  background : rgba(87, 250, 255, 0.6);
}

.primary.accent-4 {
  color : #3DF9FF !important;
}

.bg-primary.bg-accent-4 {
  background-color : #3DF9FF !important;
}

.btn-primary.btn-accent-4 {
  border-color : #4839EB !important;
  background-color : #3DF9FF !important;
}
.btn-primary.btn-accent-4:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-accent-4:focus, .btn-primary.btn-accent-4:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-accent-4 {
  border-color : #3DF9FF !important;
  color : #3DF9FF !important;
}
.btn-outline-primary.btn-outline-accent-4:hover {
  background-color : #3DF9FF !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #3DF9FF !important;
}

.border-primary.border-accent-4 {
  border : 1px solid #3DF9FF !important;
}

.border-top-primary.border-top-accent-4 {
  border-top : 1px solid #3DF9FF !important;
}

.border-bottom-primary.border-bottom-accent-4 {
  border-bottom : 1px solid #3DF9FF !important;
}

.border-left-primary.border-left-accent-4 {
  border-left : 1px solid #3DF9FF !important;
}

.border-right-primary.border-right-accent-4 {
  border-right : 1px solid #3DF9FF !important;
}

.overlay-primary.overlay-accent-4 {
  background : #3DF9FF;
  /* The Fallback */
  background : rgba(61, 249, 255, 0.6);
}

.primary.gradient-bg {
  color : #640064 !important;
}

.bg-primary.bg-gradient-bg {
  background-color : #640064 !important;
}

.btn-primary.btn-gradient-bg {
  border-color : #4839EB !important;
  background-color : #640064 !important;
}
.btn-primary.btn-gradient-bg:hover {
  border-color : #4839EB !important;
  background-color : #3321E9 !important;
}
.btn-primary.btn-gradient-bg:focus, .btn-primary.btn-gradient-bg:active {
  border-color : #3321E9 !important;
  background-color : #2716DB !important;
}

.btn-outline-primary.btn-outline-gradient-bg {
  border-color : #640064 !important;
  color : #640064 !important;
}
.btn-outline-primary.btn-outline-gradient-bg:hover {
  background-color : #640064 !important;
}

input:focus ~ .bg-primary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #640064 !important;
}

.border-primary.border-gradient-bg {
  border : 1px solid #640064 !important;
}

.border-top-primary.border-top-gradient-bg {
  border-top : 1px solid #640064 !important;
}

.border-bottom-primary.border-bottom-gradient-bg {
  border-bottom : 1px solid #640064 !important;
}

.border-left-primary.border-left-gradient-bg {
  border-left : 1px solid #640064 !important;
}

.border-right-primary.border-right-gradient-bg {
  border-right : 1px solid #640064 !important;
}

.overlay-primary.overlay-gradient-bg {
  background : #640064;
  /* The Fallback */
  background : rgba(100, 0, 100, 0.6);
}

.secondary.gradient-light {
  color : #DCDEEC !important;
}

.bg-secondary.bg-gradient-light {
  background-color : #DCDEEC !important;
}

.btn-secondary.btn-gradient-light {
  border-color : #9AA9B7 !important;
  background-color : #DCDEEC !important;
}
.btn-secondary.btn-gradient-light:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-gradient-light:focus, .btn-secondary.btn-gradient-light:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-gradient-light {
  border-color : #DCDEEC !important;
  color : #DCDEEC !important;
}
.btn-outline-secondary.btn-outline-gradient-light:hover {
  background-color : #DCDEEC !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #DCDEEC !important;
}

.border-secondary.border-gradient-light {
  border : 1px solid #DCDEEC !important;
}

.border-top-secondary.border-top-gradient-light {
  border-top : 1px solid #DCDEEC !important;
}

.border-bottom-secondary.border-bottom-gradient-light {
  border-bottom : 1px solid #DCDEEC !important;
}

.border-left-secondary.border-left-gradient-light {
  border-left : 1px solid #DCDEEC !important;
}

.border-right-secondary.border-right-gradient-light {
  border-right : 1px solid #DCDEEC !important;
}

.overlay-secondary.overlay-gradient-light {
  background : #DCDEEC;
  /* The Fallback */
  background : rgba(220, 222, 236, 0.6);
}

.secondary.light-badge {
  color : white !important;
}

.bg-secondary.bg-light-badge {
  background-color : white !important;
}

.btn-secondary.btn-light-badge {
  border-color : #9AA9B7 !important;
  background-color : white !important;
}
.btn-secondary.btn-light-badge:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-light-badge:focus, .btn-secondary.btn-light-badge:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-light-badge {
  border-color : white !important;
  color : white !important;
}
.btn-outline-secondary.btn-outline-light-badge:hover {
  background-color : white !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem white !important;
}

.border-secondary.border-light-badge {
  border : 1px solid white !important;
}

.border-top-secondary.border-top-light-badge {
  border-top : 1px solid white !important;
}

.border-bottom-secondary.border-bottom-light-badge {
  border-bottom : 1px solid white !important;
}

.border-left-secondary.border-left-light-badge {
  border-left : 1px solid white !important;
}

.border-right-secondary.border-right-light-badge {
  border-right : 1px solid white !important;
}

.overlay-secondary.overlay-light-badge {
  background : white;
  /* The Fallback */
  background : rgba(255, 255, 255, 0.6);
}

.secondary.lighten-5 {
  color : white !important;
}

.bg-secondary.bg-lighten-5 {
  background-color : white !important;
}

.btn-secondary.btn-lighten-5 {
  border-color : #9AA9B7 !important;
  background-color : white !important;
}
.btn-secondary.btn-lighten-5:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-lighten-5:focus, .btn-secondary.btn-lighten-5:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-lighten-5 {
  border-color : white !important;
  color : white !important;
}
.btn-outline-secondary.btn-outline-lighten-5:hover {
  background-color : white !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem white !important;
}

.border-secondary.border-lighten-5 {
  border : 1px solid white !important;
}

.border-top-secondary.border-top-lighten-5 {
  border-top : 1px solid white !important;
}

.border-bottom-secondary.border-bottom-lighten-5 {
  border-bottom : 1px solid white !important;
}

.border-left-secondary.border-left-lighten-5 {
  border-left : 1px solid white !important;
}

.border-right-secondary.border-right-lighten-5 {
  border-right : 1px solid white !important;
}

.overlay-secondary.overlay-lighten-5 {
  background : white;
  /* The Fallback */
  background : rgba(255, 255, 255, 0.6);
}

.secondary.lighten-4 {
  color : #F3F5F7 !important;
}

.bg-secondary.bg-lighten-4 {
  background-color : #F3F5F7 !important;
}

.btn-secondary.btn-lighten-4 {
  border-color : #9AA9B7 !important;
  background-color : #F3F5F7 !important;
}
.btn-secondary.btn-lighten-4:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-lighten-4:focus, .btn-secondary.btn-lighten-4:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-lighten-4 {
  border-color : #F3F5F7 !important;
  color : #F3F5F7 !important;
}
.btn-outline-secondary.btn-outline-lighten-4:hover {
  background-color : #F3F5F7 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F3F5F7 !important;
}

.border-secondary.border-lighten-4 {
  border : 1px solid #F3F5F7 !important;
}

.border-top-secondary.border-top-lighten-4 {
  border-top : 1px solid #F3F5F7 !important;
}

.border-bottom-secondary.border-bottom-lighten-4 {
  border-bottom : 1px solid #F3F5F7 !important;
}

.border-left-secondary.border-left-lighten-4 {
  border-left : 1px solid #F3F5F7 !important;
}

.border-right-secondary.border-right-lighten-4 {
  border-right : 1px solid #F3F5F7 !important;
}

.overlay-secondary.overlay-lighten-4 {
  background : #F3F5F7;
  /* The Fallback */
  background : rgba(243, 245, 247, 0.6);
}

.secondary.lighten-3 {
  color : #E5E8EC !important;
}

.bg-secondary.bg-lighten-3 {
  background-color : #E5E8EC !important;
}

.btn-secondary.btn-lighten-3 {
  border-color : #9AA9B7 !important;
  background-color : #E5E8EC !important;
}
.btn-secondary.btn-lighten-3:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-lighten-3:focus, .btn-secondary.btn-lighten-3:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-lighten-3 {
  border-color : #E5E8EC !important;
  color : #E5E8EC !important;
}
.btn-outline-secondary.btn-outline-lighten-3:hover {
  background-color : #E5E8EC !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #E5E8EC !important;
}

.border-secondary.border-lighten-3 {
  border : 1px solid #E5E8EC !important;
}

.border-top-secondary.border-top-lighten-3 {
  border-top : 1px solid #E5E8EC !important;
}

.border-bottom-secondary.border-bottom-lighten-3 {
  border-bottom : 1px solid #E5E8EC !important;
}

.border-left-secondary.border-left-lighten-3 {
  border-left : 1px solid #E5E8EC !important;
}

.border-right-secondary.border-right-lighten-3 {
  border-right : 1px solid #E5E8EC !important;
}

.overlay-secondary.overlay-lighten-3 {
  background : #E5E8EC;
  /* The Fallback */
  background : rgba(229, 232, 236, 0.6);
}

.secondary.lighten-2 {
  color : #D6DCE1 !important;
}

.bg-secondary.bg-lighten-2 {
  background-color : #D6DCE1 !important;
}

.btn-secondary.btn-lighten-2 {
  border-color : #9AA9B7 !important;
  background-color : #D6DCE1 !important;
}
.btn-secondary.btn-lighten-2:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-lighten-2:focus, .btn-secondary.btn-lighten-2:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-lighten-2 {
  border-color : #D6DCE1 !important;
  color : #D6DCE1 !important;
}
.btn-outline-secondary.btn-outline-lighten-2:hover {
  background-color : #D6DCE1 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #D6DCE1 !important;
}

.border-secondary.border-lighten-2 {
  border : 1px solid #D6DCE1 !important;
}

.border-top-secondary.border-top-lighten-2 {
  border-top : 1px solid #D6DCE1 !important;
}

.border-bottom-secondary.border-bottom-lighten-2 {
  border-bottom : 1px solid #D6DCE1 !important;
}

.border-left-secondary.border-left-lighten-2 {
  border-left : 1px solid #D6DCE1 !important;
}

.border-right-secondary.border-right-lighten-2 {
  border-right : 1px solid #D6DCE1 !important;
}

.overlay-secondary.overlay-lighten-2 {
  background : #D6DCE1;
  /* The Fallback */
  background : rgba(214, 220, 225, 0.6);
}

.secondary.lighten-1 {
  color : #C7CFD7 !important;
}

.bg-secondary.bg-lighten-1 {
  background-color : #C7CFD7 !important;
}

.btn-secondary.btn-lighten-1 {
  border-color : #9AA9B7 !important;
  background-color : #C7CFD7 !important;
}
.btn-secondary.btn-lighten-1:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-lighten-1:focus, .btn-secondary.btn-lighten-1:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-lighten-1 {
  border-color : #C7CFD7 !important;
  color : #C7CFD7 !important;
}
.btn-outline-secondary.btn-outline-lighten-1:hover {
  background-color : #C7CFD7 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #C7CFD7 !important;
}

.border-secondary.border-lighten-1 {
  border : 1px solid #C7CFD7 !important;
}

.border-top-secondary.border-top-lighten-1 {
  border-top : 1px solid #C7CFD7 !important;
}

.border-bottom-secondary.border-bottom-lighten-1 {
  border-bottom : 1px solid #C7CFD7 !important;
}

.border-left-secondary.border-left-lighten-1 {
  border-left : 1px solid #C7CFD7 !important;
}

.border-right-secondary.border-right-lighten-1 {
  border-right : 1px solid #C7CFD7 !important;
}

.overlay-secondary.overlay-lighten-1 {
  background : #C7CFD7;
  /* The Fallback */
  background : rgba(199, 207, 215, 0.6);
}

.secondary {
  color : #B8C2CC !important;
}

.bg-secondary {
  background-color : #B8C2CC !important;
}
.bg-secondary .card-header, .bg-secondary .card-footer {
  background-color : transparent;
}

.alert-secondary {
  background : rgba(184, 194, 204, 0.2) !important;
  color : #B8C2CC !important;
}
.alert-secondary .alert-heading {
  box-shadow : rgba(184, 194, 204, 0.4) 0 6px 15px -7px;
}
.alert-secondary .alert-link {
  color : #A9B5C1 !important;
}

.bg-rgba-secondary {
  background : rgba(184, 194, 204, 0.15) !important;
}

.border-secondary {
  border : 1px solid #B8C2CC !important;
}

.border-top-secondary {
  border-top : 1px solid #B8C2CC;
}

.border-bottom-secondary {
  border-bottom : 1px solid #B8C2CC;
}

.border-left-secondary {
  border-left : 1px solid #B8C2CC;
}

.border-right-secondary {
  border-right : 1px solid #B8C2CC;
}

.bg-secondary.badge-glow, .border-secondary.badge-glow, .badge-secondary.badge-glow {
  box-shadow : 0 0 10px #B8C2CC;
}

.badge.badge-secondary {
  background-color : #B8C2CC;
}

.badge.badge-light-secondary {
  background-color : white;
  color : #B8C2CC !important;
  font-weight : 500;
}

.overlay-secondary {
  background : #B8C2CC;
  /* The Fallback */
  background : rgba(184, 194, 204, 0.6);
}

.btn-secondary {
  border-color : #9AA9B7 !important;
  background-color : #B8C2CC !important;
  color : #FFFFFF;
}
.btn-secondary:hover {
  border-color : #A9B5C1 !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #B8C2CC;
}
.btn-secondary:focus, .btn-secondary:active {
  color : #FFFFFF !important;
}

.btn-flat-secondary {
  background-color : transparent;
  color : #B8C2CC;
}
.btn-flat-secondary:hover {
  color : #B8C2CC;
  background-color : rgba(184, 194, 204, 0.1);
}
.btn-flat-secondary:active, .btn-flat-secondary:focus {
  background-color : #B8C2CC;
  color : #FFFFFF !important;
}

.btn-relief-secondary {
  background-color : #B8C2CC;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-secondary:hover {
  color : #FFFFFF;
}
.btn-relief-secondary:active, .btn-relief-secondary:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-secondary {
  border : 1px solid #B8C2CC;
  background-color : transparent;
  color : #B8C2CC;
}
.btn-outline-secondary:hover {
  background-color : rgba(184, 194, 204, 0.08);
  color : #B8C2CC;
}
.btn-outline-secondary:active {
  color : #FFFFFF !important;
}

.btn-secondary ~ .dropdown-menu .dropdown-item.active, .btn-outline-secondary ~ .dropdown-menu .dropdown-item.active, .btn-flat-secondary ~ .dropdown-menu .dropdown-item.active {
  background-color : #B8C2CC;
  color : #FFFFFF;
}
.btn-secondary ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-secondary ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-secondary ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-secondary ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-secondary ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-secondary ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #B8C2CC;
}

.dropdown.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:hover i {
  color : #B8C2CC;
}

.dropdown.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-secondary ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-secondary {
  background-color : #B8C2CC;
}

.pagination-secondary .page-item.active .page-link {
  background : #B8C2CC;
  color : #FFFFFF;
}
.pagination-secondary .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-secondary .page-item .page-link:hover {
  color : #B8C2CC;
}

.pagination-secondary .page-item.prev-item .page-link:hover, .pagination-secondary .page-item.next-item .page-link:hover {
  background : #B8C2CC;
  color : #FFFFFF;
}

.nav-pill-secondary .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #B8C2CC !important;
}

.progress-bar-secondary {
  background-color : rgba(184, 194, 204, 0.1);
}
.progress-bar-secondary .progress-bar {
  background-color : #B8C2CC;
}

.chip-secondary {
  background-color : #B8C2CC !important;
}
.chip-secondary .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-secondary .divider-text:before, .divider.divider-secondary .divider-text:after {
  border-color : #B8C2CC !important;
}

input[type='checkbox'].bg-secondary + .custom-control-label:before, input[type='radio'].bg-secondary + .custom-control-label:before {
  background-color : #B8C2CC !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #B8C2CC !important;
}

.vs-checkbox-secondary input:checked ~ .vs-checkbox {
  border-color : #B8C2CC !important;
}
.vs-checkbox-secondary input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #B8C2CC !important;
}

.vs-checkbox-secondary input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #B8C2CC !important;
}

.custom-switch-secondary .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #B8C2CC !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-secondary input:checked ~ .vs-radio .vs-radio--circle {
  background : #B8C2CC !important;
  box-shadow : 0 3px 12px 0 rgba(184, 194, 204, 0.4) !important;
}

.secondary.darken-1 {
  color : #A9B5C1 !important;
}

.bg-secondary.bg-darken-1 {
  background-color : #A9B5C1 !important;
}

.btn-secondary.btn-darken-1 {
  border-color : #9AA9B7 !important;
  background-color : #A9B5C1 !important;
}
.btn-secondary.btn-darken-1:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-darken-1:focus, .btn-secondary.btn-darken-1:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-darken-1 {
  border-color : #A9B5C1 !important;
  color : #A9B5C1 !important;
}
.btn-outline-secondary.btn-outline-darken-1:hover {
  background-color : #A9B5C1 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #A9B5C1 !important;
}

.border-secondary.border-darken-1 {
  border : 1px solid #A9B5C1 !important;
}

.border-top-secondary.border-top-darken-1 {
  border-top : 1px solid #A9B5C1 !important;
}

.border-bottom-secondary.border-bottom-darken-1 {
  border-bottom : 1px solid #A9B5C1 !important;
}

.border-left-secondary.border-left-darken-1 {
  border-left : 1px solid #A9B5C1 !important;
}

.border-right-secondary.border-right-darken-1 {
  border-right : 1px solid #A9B5C1 !important;
}

.overlay-secondary.overlay-darken-1 {
  background : #A9B5C1;
  /* The Fallback */
  background : rgba(169, 181, 193, 0.6);
}

.secondary.darken-2 {
  color : #9AA9B7 !important;
}

.bg-secondary.bg-darken-2 {
  background-color : #9AA9B7 !important;
}

.btn-secondary.btn-darken-2 {
  border-color : #9AA9B7 !important;
  background-color : #9AA9B7 !important;
}
.btn-secondary.btn-darken-2:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-darken-2:focus, .btn-secondary.btn-darken-2:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-darken-2 {
  border-color : #9AA9B7 !important;
  color : #9AA9B7 !important;
}
.btn-outline-secondary.btn-outline-darken-2:hover {
  background-color : #9AA9B7 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #9AA9B7 !important;
}

.border-secondary.border-darken-2 {
  border : 1px solid #9AA9B7 !important;
}

.border-top-secondary.border-top-darken-2 {
  border-top : 1px solid #9AA9B7 !important;
}

.border-bottom-secondary.border-bottom-darken-2 {
  border-bottom : 1px solid #9AA9B7 !important;
}

.border-left-secondary.border-left-darken-2 {
  border-left : 1px solid #9AA9B7 !important;
}

.border-right-secondary.border-right-darken-2 {
  border-right : 1px solid #9AA9B7 !important;
}

.overlay-secondary.overlay-darken-2 {
  background : #9AA9B7;
  /* The Fallback */
  background : rgba(154, 169, 183, 0.6);
}

.secondary.darken-3 {
  color : #8B9CAC !important;
}

.bg-secondary.bg-darken-3 {
  background-color : #8B9CAC !important;
}

.btn-secondary.btn-darken-3 {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-darken-3:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-darken-3:focus, .btn-secondary.btn-darken-3:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-darken-3 {
  border-color : #8B9CAC !important;
  color : #8B9CAC !important;
}
.btn-outline-secondary.btn-outline-darken-3:hover {
  background-color : #8B9CAC !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #8B9CAC !important;
}

.border-secondary.border-darken-3 {
  border : 1px solid #8B9CAC !important;
}

.border-top-secondary.border-top-darken-3 {
  border-top : 1px solid #8B9CAC !important;
}

.border-bottom-secondary.border-bottom-darken-3 {
  border-bottom : 1px solid #8B9CAC !important;
}

.border-left-secondary.border-left-darken-3 {
  border-left : 1px solid #8B9CAC !important;
}

.border-right-secondary.border-right-darken-3 {
  border-right : 1px solid #8B9CAC !important;
}

.overlay-secondary.overlay-darken-3 {
  background : #8B9CAC;
  /* The Fallback */
  background : rgba(139, 156, 172, 0.6);
}

.secondary.darken-4 {
  color : #7D8FA1 !important;
}

.bg-secondary.bg-darken-4 {
  background-color : #7D8FA1 !important;
}

.btn-secondary.btn-darken-4 {
  border-color : #9AA9B7 !important;
  background-color : #7D8FA1 !important;
}
.btn-secondary.btn-darken-4:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-darken-4:focus, .btn-secondary.btn-darken-4:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-darken-4 {
  border-color : #7D8FA1 !important;
  color : #7D8FA1 !important;
}
.btn-outline-secondary.btn-outline-darken-4:hover {
  background-color : #7D8FA1 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #7D8FA1 !important;
}

.border-secondary.border-darken-4 {
  border : 1px solid #7D8FA1 !important;
}

.border-top-secondary.border-top-darken-4 {
  border-top : 1px solid #7D8FA1 !important;
}

.border-bottom-secondary.border-bottom-darken-4 {
  border-bottom : 1px solid #7D8FA1 !important;
}

.border-left-secondary.border-left-darken-4 {
  border-left : 1px solid #7D8FA1 !important;
}

.border-right-secondary.border-right-darken-4 {
  border-right : 1px solid #7D8FA1 !important;
}

.overlay-secondary.overlay-darken-4 {
  background : #7D8FA1;
  /* The Fallback */
  background : rgba(125, 143, 161, 0.6);
}

.secondary.gradient-bg {
  color : #640064 !important;
}

.bg-secondary.bg-gradient-bg {
  background-color : #640064 !important;
}

.btn-secondary.btn-gradient-bg {
  border-color : #9AA9B7 !important;
  background-color : #640064 !important;
}
.btn-secondary.btn-gradient-bg:hover {
  border-color : #9AA9B7 !important;
  background-color : #8B9CAC !important;
}
.btn-secondary.btn-gradient-bg:focus, .btn-secondary.btn-gradient-bg:active {
  border-color : #8B9CAC !important;
  background-color : #7D8FA1 !important;
}

.btn-outline-secondary.btn-outline-gradient-bg {
  border-color : #640064 !important;
  color : #640064 !important;
}
.btn-outline-secondary.btn-outline-gradient-bg:hover {
  background-color : #640064 !important;
}

input:focus ~ .bg-secondary {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #640064 !important;
}

.border-secondary.border-gradient-bg {
  border : 1px solid #640064 !important;
}

.border-top-secondary.border-top-gradient-bg {
  border-top : 1px solid #640064 !important;
}

.border-bottom-secondary.border-bottom-gradient-bg {
  border-bottom : 1px solid #640064 !important;
}

.border-left-secondary.border-left-gradient-bg {
  border-left : 1px solid #640064 !important;
}

.border-right-secondary.border-right-gradient-bg {
  border-right : 1px solid #640064 !important;
}

.overlay-secondary.overlay-gradient-bg {
  background : #640064;
  /* The Fallback */
  background : rgba(100, 0, 100, 0.6);
}

.success.gradient-light {
  color : #4AE9D3 !important;
}

.bg-success.bg-gradient-light {
  background-color : #4AE9D3 !important;
}

.btn-success.btn-gradient-light {
  border-color : #1F9D57 !important;
  background-color : #4AE9D3 !important;
}
.btn-success.btn-gradient-light:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-gradient-light:focus, .btn-success.btn-gradient-light:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-gradient-light {
  border-color : #4AE9D3 !important;
  color : #4AE9D3 !important;
}
.btn-outline-success.btn-outline-gradient-light:hover {
  background-color : #4AE9D3 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #4AE9D3 !important;
}

.border-success.border-gradient-light {
  border : 1px solid #4AE9D3 !important;
}

.border-top-success.border-top-gradient-light {
  border-top : 1px solid #4AE9D3 !important;
}

.border-bottom-success.border-bottom-gradient-light {
  border-bottom : 1px solid #4AE9D3 !important;
}

.border-left-success.border-left-gradient-light {
  border-left : 1px solid #4AE9D3 !important;
}

.border-right-success.border-right-gradient-light {
  border-right : 1px solid #4AE9D3 !important;
}

.overlay-success.overlay-gradient-light {
  background : #4AE9D3;
  /* The Fallback */
  background : rgba(74, 233, 211, 0.6);
}

.success.light-badge {
  color : #C7F4DB !important;
}

.bg-success.bg-light-badge {
  background-color : #C7F4DB !important;
}

.btn-success.btn-light-badge {
  border-color : #1F9D57 !important;
  background-color : #C7F4DB !important;
}
.btn-success.btn-light-badge:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-light-badge:focus, .btn-success.btn-light-badge:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-light-badge {
  border-color : #C7F4DB !important;
  color : #C7F4DB !important;
}
.btn-outline-success.btn-outline-light-badge:hover {
  background-color : #C7F4DB !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #C7F4DB !important;
}

.border-success.border-light-badge {
  border : 1px solid #C7F4DB !important;
}

.border-top-success.border-top-light-badge {
  border-top : 1px solid #C7F4DB !important;
}

.border-bottom-success.border-bottom-light-badge {
  border-bottom : 1px solid #C7F4DB !important;
}

.border-left-success.border-left-light-badge {
  border-left : 1px solid #C7F4DB !important;
}

.border-right-success.border-right-light-badge {
  border-right : 1px solid #C7F4DB !important;
}

.overlay-success.overlay-light-badge {
  background : #C7F4DB;
  /* The Fallback */
  background : rgba(199, 244, 219, 0.6);
}

.success.lighten-5 {
  color : #88E7B2 !important;
}

.bg-success.bg-lighten-5 {
  background-color : #88E7B2 !important;
}

.btn-success.btn-lighten-5 {
  border-color : #1F9D57 !important;
  background-color : #88E7B2 !important;
}
.btn-success.btn-lighten-5:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-lighten-5:focus, .btn-success.btn-lighten-5:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-lighten-5 {
  border-color : #88E7B2 !important;
  color : #88E7B2 !important;
}
.btn-outline-success.btn-outline-lighten-5:hover {
  background-color : #88E7B2 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #88E7B2 !important;
}

.border-success.border-lighten-5 {
  border : 1px solid #88E7B2 !important;
}

.border-top-success.border-top-lighten-5 {
  border-top : 1px solid #88E7B2 !important;
}

.border-bottom-success.border-bottom-lighten-5 {
  border-bottom : 1px solid #88E7B2 !important;
}

.border-left-success.border-left-lighten-5 {
  border-left : 1px solid #88E7B2 !important;
}

.border-right-success.border-right-lighten-5 {
  border-right : 1px solid #88E7B2 !important;
}

.overlay-success.overlay-lighten-5 {
  background : #88E7B2;
  /* The Fallback */
  background : rgba(136, 231, 178, 0.6);
}

.success.lighten-4 {
  color : #72E3A4 !important;
}

.bg-success.bg-lighten-4 {
  background-color : #72E3A4 !important;
}

.btn-success.btn-lighten-4 {
  border-color : #1F9D57 !important;
  background-color : #72E3A4 !important;
}
.btn-success.btn-lighten-4:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-lighten-4:focus, .btn-success.btn-lighten-4:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-lighten-4 {
  border-color : #72E3A4 !important;
  color : #72E3A4 !important;
}
.btn-outline-success.btn-outline-lighten-4:hover {
  background-color : #72E3A4 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #72E3A4 !important;
}

.border-success.border-lighten-4 {
  border : 1px solid #72E3A4 !important;
}

.border-top-success.border-top-lighten-4 {
  border-top : 1px solid #72E3A4 !important;
}

.border-bottom-success.border-bottom-lighten-4 {
  border-bottom : 1px solid #72E3A4 !important;
}

.border-left-success.border-left-lighten-4 {
  border-left : 1px solid #72E3A4 !important;
}

.border-right-success.border-right-lighten-4 {
  border-right : 1px solid #72E3A4 !important;
}

.overlay-success.overlay-lighten-4 {
  background : #72E3A4;
  /* The Fallback */
  background : rgba(114, 227, 164, 0.6);
}

.success.lighten-3 {
  color : #5DDE97 !important;
}

.bg-success.bg-lighten-3 {
  background-color : #5DDE97 !important;
}

.btn-success.btn-lighten-3 {
  border-color : #1F9D57 !important;
  background-color : #5DDE97 !important;
}
.btn-success.btn-lighten-3:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-lighten-3:focus, .btn-success.btn-lighten-3:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-lighten-3 {
  border-color : #5DDE97 !important;
  color : #5DDE97 !important;
}
.btn-outline-success.btn-outline-lighten-3:hover {
  background-color : #5DDE97 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #5DDE97 !important;
}

.border-success.border-lighten-3 {
  border : 1px solid #5DDE97 !important;
}

.border-top-success.border-top-lighten-3 {
  border-top : 1px solid #5DDE97 !important;
}

.border-bottom-success.border-bottom-lighten-3 {
  border-bottom : 1px solid #5DDE97 !important;
}

.border-left-success.border-left-lighten-3 {
  border-left : 1px solid #5DDE97 !important;
}

.border-right-success.border-right-lighten-3 {
  border-right : 1px solid #5DDE97 !important;
}

.overlay-success.overlay-lighten-3 {
  background : #5DDE97;
  /* The Fallback */
  background : rgba(93, 222, 151, 0.6);
}

.success.lighten-2 {
  color : #48DA89 !important;
}

.bg-success.bg-lighten-2 {
  background-color : #48DA89 !important;
}

.btn-success.btn-lighten-2 {
  border-color : #1F9D57 !important;
  background-color : #48DA89 !important;
}
.btn-success.btn-lighten-2:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-lighten-2:focus, .btn-success.btn-lighten-2:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-lighten-2 {
  border-color : #48DA89 !important;
  color : #48DA89 !important;
}
.btn-outline-success.btn-outline-lighten-2:hover {
  background-color : #48DA89 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #48DA89 !important;
}

.border-success.border-lighten-2 {
  border : 1px solid #48DA89 !important;
}

.border-top-success.border-top-lighten-2 {
  border-top : 1px solid #48DA89 !important;
}

.border-bottom-success.border-bottom-lighten-2 {
  border-bottom : 1px solid #48DA89 !important;
}

.border-left-success.border-left-lighten-2 {
  border-left : 1px solid #48DA89 !important;
}

.border-right-success.border-right-lighten-2 {
  border-right : 1px solid #48DA89 !important;
}

.overlay-success.overlay-lighten-2 {
  background : #48DA89;
  /* The Fallback */
  background : rgba(72, 218, 137, 0.6);
}

.success.lighten-1 {
  color : #33D67C !important;
}

.bg-success.bg-lighten-1 {
  background-color : #33D67C !important;
}

.btn-success.btn-lighten-1 {
  border-color : #1F9D57 !important;
  background-color : #33D67C !important;
}
.btn-success.btn-lighten-1:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-lighten-1:focus, .btn-success.btn-lighten-1:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-lighten-1 {
  border-color : #33D67C !important;
  color : #33D67C !important;
}
.btn-outline-success.btn-outline-lighten-1:hover {
  background-color : #33D67C !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #33D67C !important;
}

.border-success.border-lighten-1 {
  border : 1px solid #33D67C !important;
}

.border-top-success.border-top-lighten-1 {
  border-top : 1px solid #33D67C !important;
}

.border-bottom-success.border-bottom-lighten-1 {
  border-bottom : 1px solid #33D67C !important;
}

.border-left-success.border-left-lighten-1 {
  border-left : 1px solid #33D67C !important;
}

.border-right-success.border-right-lighten-1 {
  border-right : 1px solid #33D67C !important;
}

.overlay-success.overlay-lighten-1 {
  background : #33D67C;
  /* The Fallback */
  background : rgba(51, 214, 124, 0.6);
}

.success {
  color : #28C76F !important;
}

.bg-success {
  background-color : #28C76F !important;
}
.bg-success .card-header, .bg-success .card-footer {
  background-color : transparent;
}

.alert-success {
  background : rgba(40, 199, 111, 0.2) !important;
  color : #28C76F !important;
}
.alert-success .alert-heading {
  box-shadow : rgba(40, 199, 111, 0.4) 0 6px 15px -7px;
}
.alert-success .alert-link {
  color : #24B263 !important;
}

.bg-rgba-success {
  background : rgba(40, 199, 111, 0.15) !important;
}

.border-success {
  border : 1px solid #28C76F !important;
}

.border-top-success {
  border-top : 1px solid #28C76F;
}

.border-bottom-success {
  border-bottom : 1px solid #28C76F;
}

.border-left-success {
  border-left : 1px solid #28C76F;
}

.border-right-success {
  border-right : 1px solid #28C76F;
}

.bg-success.badge-glow, .border-success.badge-glow, .badge-success.badge-glow {
  box-shadow : 0 0 10px #28C76F;
}

.badge.badge-success {
  background-color : #28C76F;
}

.badge.badge-light-success {
  background-color : #C7F4DB;
  color : #28C76F !important;
  font-weight : 500;
}

.overlay-success {
  background : #28C76F;
  /* The Fallback */
  background : rgba(40, 199, 111, 0.6);
}

.btn-success {
  border-color : #1F9D57 !important;
  background-color : #28C76F !important;
  color : #FFFFFF;
}
.btn-success:hover {
  border-color : #24B263 !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #28C76F;
}
.btn-success:focus, .btn-success:active {
  color : #FFFFFF !important;
}

.btn-flat-success {
  background-color : transparent;
  color : #28C76F;
}
.btn-flat-success:hover {
  color : #28C76F;
  background-color : rgba(40, 199, 111, 0.1);
}
.btn-flat-success:active, .btn-flat-success:focus {
  background-color : #28C76F;
  color : #FFFFFF !important;
}

.btn-relief-success {
  background-color : #28C76F;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-success:hover {
  color : #FFFFFF;
}
.btn-relief-success:active, .btn-relief-success:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-success {
  border : 1px solid #28C76F;
  background-color : transparent;
  color : #28C76F;
}
.btn-outline-success:hover {
  background-color : rgba(40, 199, 111, 0.08);
  color : #28C76F;
}
.btn-outline-success:active {
  color : #FFFFFF !important;
}

.btn-success ~ .dropdown-menu .dropdown-item.active, .btn-outline-success ~ .dropdown-menu .dropdown-item.active, .btn-flat-success ~ .dropdown-menu .dropdown-item.active {
  background-color : #28C76F;
  color : #FFFFFF;
}
.btn-success ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-success ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-success ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-success ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-success ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-success ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #28C76F;
}

.dropdown.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:hover i {
  color : #28C76F;
}

.dropdown.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-success ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-success {
  background-color : #28C76F;
}

.pagination-success .page-item.active .page-link {
  background : #28C76F;
  color : #FFFFFF;
}
.pagination-success .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-success .page-item .page-link:hover {
  color : #28C76F;
}

.pagination-success .page-item.prev-item .page-link:hover, .pagination-success .page-item.next-item .page-link:hover {
  background : #28C76F;
  color : #FFFFFF;
}

.nav-pill-success .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #28C76F !important;
}

.progress-bar-success {
  background-color : rgba(40, 199, 111, 0.1);
}
.progress-bar-success .progress-bar {
  background-color : #28C76F;
}

.chip-success {
  background-color : #28C76F !important;
}
.chip-success .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-success .divider-text:before, .divider.divider-success .divider-text:after {
  border-color : #28C76F !important;
}

input[type='checkbox'].bg-success + .custom-control-label:before, input[type='radio'].bg-success + .custom-control-label:before {
  background-color : #28C76F !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #28C76F !important;
}

.vs-checkbox-success input:checked ~ .vs-checkbox {
  border-color : #28C76F !important;
}
.vs-checkbox-success input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #28C76F !important;
}

.vs-checkbox-success input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #28C76F !important;
}

.custom-switch-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #28C76F !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-success input:checked ~ .vs-radio .vs-radio--circle {
  background : #28C76F !important;
  box-shadow : 0 3px 12px 0 rgba(40, 199, 111, 0.4) !important;
}

.success.darken-1 {
  color : #24B263 !important;
}

.bg-success.bg-darken-1 {
  background-color : #24B263 !important;
}

.btn-success.btn-darken-1 {
  border-color : #1F9D57 !important;
  background-color : #24B263 !important;
}
.btn-success.btn-darken-1:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-darken-1:focus, .btn-success.btn-darken-1:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-darken-1 {
  border-color : #24B263 !important;
  color : #24B263 !important;
}
.btn-outline-success.btn-outline-darken-1:hover {
  background-color : #24B263 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #24B263 !important;
}

.border-success.border-darken-1 {
  border : 1px solid #24B263 !important;
}

.border-top-success.border-top-darken-1 {
  border-top : 1px solid #24B263 !important;
}

.border-bottom-success.border-bottom-darken-1 {
  border-bottom : 1px solid #24B263 !important;
}

.border-left-success.border-left-darken-1 {
  border-left : 1px solid #24B263 !important;
}

.border-right-success.border-right-darken-1 {
  border-right : 1px solid #24B263 !important;
}

.overlay-success.overlay-darken-1 {
  background : #24B263;
  /* The Fallback */
  background : rgba(36, 178, 99, 0.6);
}

.success.darken-2 {
  color : #1F9D57 !important;
}

.bg-success.bg-darken-2 {
  background-color : #1F9D57 !important;
}

.btn-success.btn-darken-2 {
  border-color : #1F9D57 !important;
  background-color : #1F9D57 !important;
}
.btn-success.btn-darken-2:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-darken-2:focus, .btn-success.btn-darken-2:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-darken-2 {
  border-color : #1F9D57 !important;
  color : #1F9D57 !important;
}
.btn-outline-success.btn-outline-darken-2:hover {
  background-color : #1F9D57 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #1F9D57 !important;
}

.border-success.border-darken-2 {
  border : 1px solid #1F9D57 !important;
}

.border-top-success.border-top-darken-2 {
  border-top : 1px solid #1F9D57 !important;
}

.border-bottom-success.border-bottom-darken-2 {
  border-bottom : 1px solid #1F9D57 !important;
}

.border-left-success.border-left-darken-2 {
  border-left : 1px solid #1F9D57 !important;
}

.border-right-success.border-right-darken-2 {
  border-right : 1px solid #1F9D57 !important;
}

.overlay-success.overlay-darken-2 {
  background : #1F9D57;
  /* The Fallback */
  background : rgba(31, 157, 87, 0.6);
}

.success.darken-3 {
  color : #1B874B !important;
}

.bg-success.bg-darken-3 {
  background-color : #1B874B !important;
}

.btn-success.btn-darken-3 {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-darken-3:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-darken-3:focus, .btn-success.btn-darken-3:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-darken-3 {
  border-color : #1B874B !important;
  color : #1B874B !important;
}
.btn-outline-success.btn-outline-darken-3:hover {
  background-color : #1B874B !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #1B874B !important;
}

.border-success.border-darken-3 {
  border : 1px solid #1B874B !important;
}

.border-top-success.border-top-darken-3 {
  border-top : 1px solid #1B874B !important;
}

.border-bottom-success.border-bottom-darken-3 {
  border-bottom : 1px solid #1B874B !important;
}

.border-left-success.border-left-darken-3 {
  border-left : 1px solid #1B874B !important;
}

.border-right-success.border-right-darken-3 {
  border-right : 1px solid #1B874B !important;
}

.overlay-success.overlay-darken-3 {
  background : #1B874B;
  /* The Fallback */
  background : rgba(27, 135, 75, 0.6);
}

.success.darken-4 {
  color : #177240 !important;
}

.bg-success.bg-darken-4 {
  background-color : #177240 !important;
}

.btn-success.btn-darken-4 {
  border-color : #1F9D57 !important;
  background-color : #177240 !important;
}
.btn-success.btn-darken-4:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-darken-4:focus, .btn-success.btn-darken-4:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-darken-4 {
  border-color : #177240 !important;
  color : #177240 !important;
}
.btn-outline-success.btn-outline-darken-4:hover {
  background-color : #177240 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #177240 !important;
}

.border-success.border-darken-4 {
  border : 1px solid #177240 !important;
}

.border-top-success.border-top-darken-4 {
  border-top : 1px solid #177240 !important;
}

.border-bottom-success.border-bottom-darken-4 {
  border-bottom : 1px solid #177240 !important;
}

.border-left-success.border-left-darken-4 {
  border-left : 1px solid #177240 !important;
}

.border-right-success.border-right-darken-4 {
  border-right : 1px solid #177240 !important;
}

.overlay-success.overlay-darken-4 {
  background : #177240;
  /* The Fallback */
  background : rgba(23, 114, 64, 0.6);
}

.success.accent-1 {
  color : #E1FFF1 !important;
}

.bg-success.bg-accent-1 {
  background-color : #E1FFF1 !important;
}

.btn-success.btn-accent-1 {
  border-color : #1F9D57 !important;
  background-color : #E1FFF1 !important;
}
.btn-success.btn-accent-1:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-accent-1:focus, .btn-success.btn-accent-1:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-accent-1 {
  border-color : #E1FFF1 !important;
  color : #E1FFF1 !important;
}
.btn-outline-success.btn-outline-accent-1:hover {
  background-color : #E1FFF1 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #E1FFF1 !important;
}

.border-success.border-accent-1 {
  border : 1px solid #E1FFF1 !important;
}

.border-top-success.border-top-accent-1 {
  border-top : 1px solid #E1FFF1 !important;
}

.border-bottom-success.border-bottom-accent-1 {
  border-bottom : 1px solid #E1FFF1 !important;
}

.border-left-success.border-left-accent-1 {
  border-left : 1px solid #E1FFF1 !important;
}

.border-right-success.border-right-accent-1 {
  border-right : 1px solid #E1FFF1 !important;
}

.overlay-success.overlay-accent-1 {
  background : #E1FFF1;
  /* The Fallback */
  background : rgba(225, 255, 241, 0.6);
}

.success.accent-2 {
  color : #AEFFD9 !important;
}

.bg-success.bg-accent-2 {
  background-color : #AEFFD9 !important;
}

.btn-success.btn-accent-2 {
  border-color : #1F9D57 !important;
  background-color : #AEFFD9 !important;
}
.btn-success.btn-accent-2:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-accent-2:focus, .btn-success.btn-accent-2:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-accent-2 {
  border-color : #AEFFD9 !important;
  color : #AEFFD9 !important;
}
.btn-outline-success.btn-outline-accent-2:hover {
  background-color : #AEFFD9 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #AEFFD9 !important;
}

.border-success.border-accent-2 {
  border : 1px solid #AEFFD9 !important;
}

.border-top-success.border-top-accent-2 {
  border-top : 1px solid #AEFFD9 !important;
}

.border-bottom-success.border-bottom-accent-2 {
  border-bottom : 1px solid #AEFFD9 !important;
}

.border-left-success.border-left-accent-2 {
  border-left : 1px solid #AEFFD9 !important;
}

.border-right-success.border-right-accent-2 {
  border-right : 1px solid #AEFFD9 !important;
}

.overlay-success.overlay-accent-2 {
  background : #AEFFD9;
  /* The Fallback */
  background : rgba(174, 255, 217, 0.6);
}

.success.accent-3 {
  color : #7BFFC1 !important;
}

.bg-success.bg-accent-3 {
  background-color : #7BFFC1 !important;
}

.btn-success.btn-accent-3 {
  border-color : #1F9D57 !important;
  background-color : #7BFFC1 !important;
}
.btn-success.btn-accent-3:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-accent-3:focus, .btn-success.btn-accent-3:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-accent-3 {
  border-color : #7BFFC1 !important;
  color : #7BFFC1 !important;
}
.btn-outline-success.btn-outline-accent-3:hover {
  background-color : #7BFFC1 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #7BFFC1 !important;
}

.border-success.border-accent-3 {
  border : 1px solid #7BFFC1 !important;
}

.border-top-success.border-top-accent-3 {
  border-top : 1px solid #7BFFC1 !important;
}

.border-bottom-success.border-bottom-accent-3 {
  border-bottom : 1px solid #7BFFC1 !important;
}

.border-left-success.border-left-accent-3 {
  border-left : 1px solid #7BFFC1 !important;
}

.border-right-success.border-right-accent-3 {
  border-right : 1px solid #7BFFC1 !important;
}

.overlay-success.overlay-accent-3 {
  background : #7BFFC1;
  /* The Fallback */
  background : rgba(123, 255, 193, 0.6);
}

.success.accent-4 {
  color : #62FFB5 !important;
}

.bg-success.bg-accent-4 {
  background-color : #62FFB5 !important;
}

.btn-success.btn-accent-4 {
  border-color : #1F9D57 !important;
  background-color : #62FFB5 !important;
}
.btn-success.btn-accent-4:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-accent-4:focus, .btn-success.btn-accent-4:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-accent-4 {
  border-color : #62FFB5 !important;
  color : #62FFB5 !important;
}
.btn-outline-success.btn-outline-accent-4:hover {
  background-color : #62FFB5 !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #62FFB5 !important;
}

.border-success.border-accent-4 {
  border : 1px solid #62FFB5 !important;
}

.border-top-success.border-top-accent-4 {
  border-top : 1px solid #62FFB5 !important;
}

.border-bottom-success.border-bottom-accent-4 {
  border-bottom : 1px solid #62FFB5 !important;
}

.border-left-success.border-left-accent-4 {
  border-left : 1px solid #62FFB5 !important;
}

.border-right-success.border-right-accent-4 {
  border-right : 1px solid #62FFB5 !important;
}

.overlay-success.overlay-accent-4 {
  background : #62FFB5;
  /* The Fallback */
  background : rgba(98, 255, 181, 0.6);
}

.success.gradient-bg {
  color : #0064FA !important;
}

.bg-success.bg-gradient-bg {
  background-color : #0064FA !important;
}

.btn-success.btn-gradient-bg {
  border-color : #1F9D57 !important;
  background-color : #0064FA !important;
}
.btn-success.btn-gradient-bg:hover {
  border-color : #1F9D57 !important;
  background-color : #1B874B !important;
}
.btn-success.btn-gradient-bg:focus, .btn-success.btn-gradient-bg:active {
  border-color : #1B874B !important;
  background-color : #177240 !important;
}

.btn-outline-success.btn-outline-gradient-bg {
  border-color : #0064FA !important;
  color : #0064FA !important;
}
.btn-outline-success.btn-outline-gradient-bg:hover {
  background-color : #0064FA !important;
}

input:focus ~ .bg-success {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #0064FA !important;
}

.border-success.border-gradient-bg {
  border : 1px solid #0064FA !important;
}

.border-top-success.border-top-gradient-bg {
  border-top : 1px solid #0064FA !important;
}

.border-bottom-success.border-bottom-gradient-bg {
  border-bottom : 1px solid #0064FA !important;
}

.border-left-success.border-left-gradient-bg {
  border-left : 1px solid #0064FA !important;
}

.border-right-success.border-right-gradient-bg {
  border-right : 1px solid #0064FA !important;
}

.overlay-success.overlay-gradient-bg {
  background : #0064FA;
  /* The Fallback */
  background : rgba(0, 100, 250, 0.6);
}

.info.gradient-light {
  color : #2D91FF !important;
}

.bg-info.bg-gradient-light {
  background-color : #2D91FF !important;
}

.btn-info.btn-gradient-light {
  border-color : #00A1B5 !important;
  background-color : #2D91FF !important;
}
.btn-info.btn-gradient-light:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-gradient-light:focus, .btn-info.btn-gradient-light:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-gradient-light {
  border-color : #2D91FF !important;
  color : #2D91FF !important;
}
.btn-outline-info.btn-outline-gradient-light:hover {
  background-color : #2D91FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #2D91FF !important;
}

.border-info.border-gradient-light {
  border : 1px solid #2D91FF !important;
}

.border-top-info.border-top-gradient-light {
  border-top : 1px solid #2D91FF !important;
}

.border-bottom-info.border-bottom-gradient-light {
  border-bottom : 1px solid #2D91FF !important;
}

.border-left-info.border-left-gradient-light {
  border-left : 1px solid #2D91FF !important;
}

.border-right-info.border-right-gradient-light {
  border-right : 1px solid #2D91FF !important;
}

.overlay-info.overlay-gradient-light {
  background : #2D91FF;
  /* The Fallback */
  background : rgba(45, 145, 255, 0.6);
}

.info.light-badge {
  color : #B5F7FF !important;
}

.bg-info.bg-light-badge {
  background-color : #B5F7FF !important;
}

.btn-info.btn-light-badge {
  border-color : #00A1B5 !important;
  background-color : #B5F7FF !important;
}
.btn-info.btn-light-badge:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-light-badge:focus, .btn-info.btn-light-badge:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-light-badge {
  border-color : #B5F7FF !important;
  color : #B5F7FF !important;
}
.btn-outline-info.btn-outline-light-badge:hover {
  background-color : #B5F7FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #B5F7FF !important;
}

.border-info.border-light-badge {
  border : 1px solid #B5F7FF !important;
}

.border-top-info.border-top-light-badge {
  border-top : 1px solid #B5F7FF !important;
}

.border-bottom-info.border-bottom-light-badge {
  border-bottom : 1px solid #B5F7FF !important;
}

.border-left-info.border-left-light-badge {
  border-left : 1px solid #B5F7FF !important;
}

.border-right-info.border-right-light-badge {
  border-right : 1px solid #B5F7FF !important;
}

.overlay-info.overlay-light-badge {
  background : #B5F7FF;
  /* The Fallback */
  background : rgba(181, 247, 255, 0.6);
}

.info.lighten-5 {
  color : #69EFFF !important;
}

.bg-info.bg-lighten-5 {
  background-color : #69EFFF !important;
}

.btn-info.btn-lighten-5 {
  border-color : #00A1B5 !important;
  background-color : #69EFFF !important;
}
.btn-info.btn-lighten-5:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-lighten-5:focus, .btn-info.btn-lighten-5:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-lighten-5 {
  border-color : #69EFFF !important;
  color : #69EFFF !important;
}
.btn-outline-info.btn-outline-lighten-5:hover {
  background-color : #69EFFF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #69EFFF !important;
}

.border-info.border-lighten-5 {
  border : 1px solid #69EFFF !important;
}

.border-top-info.border-top-lighten-5 {
  border-top : 1px solid #69EFFF !important;
}

.border-bottom-info.border-bottom-lighten-5 {
  border-bottom : 1px solid #69EFFF !important;
}

.border-left-info.border-left-lighten-5 {
  border-left : 1px solid #69EFFF !important;
}

.border-right-info.border-right-lighten-5 {
  border-right : 1px solid #69EFFF !important;
}

.overlay-info.overlay-lighten-5 {
  background : #69EFFF;
  /* The Fallback */
  background : rgba(105, 239, 255, 0.6);
}

.info.lighten-4 {
  color : #4FECFF !important;
}

.bg-info.bg-lighten-4 {
  background-color : #4FECFF !important;
}

.btn-info.btn-lighten-4 {
  border-color : #00A1B5 !important;
  background-color : #4FECFF !important;
}
.btn-info.btn-lighten-4:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-lighten-4:focus, .btn-info.btn-lighten-4:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-lighten-4 {
  border-color : #4FECFF !important;
  color : #4FECFF !important;
}
.btn-outline-info.btn-outline-lighten-4:hover {
  background-color : #4FECFF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #4FECFF !important;
}

.border-info.border-lighten-4 {
  border : 1px solid #4FECFF !important;
}

.border-top-info.border-top-lighten-4 {
  border-top : 1px solid #4FECFF !important;
}

.border-bottom-info.border-bottom-lighten-4 {
  border-bottom : 1px solid #4FECFF !important;
}

.border-left-info.border-left-lighten-4 {
  border-left : 1px solid #4FECFF !important;
}

.border-right-info.border-right-lighten-4 {
  border-right : 1px solid #4FECFF !important;
}

.overlay-info.overlay-lighten-4 {
  background : #4FECFF;
  /* The Fallback */
  background : rgba(79, 236, 255, 0.6);
}

.info.lighten-3 {
  color : #36E9FF !important;
}

.bg-info.bg-lighten-3 {
  background-color : #36E9FF !important;
}

.btn-info.btn-lighten-3 {
  border-color : #00A1B5 !important;
  background-color : #36E9FF !important;
}
.btn-info.btn-lighten-3:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-lighten-3:focus, .btn-info.btn-lighten-3:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-lighten-3 {
  border-color : #36E9FF !important;
  color : #36E9FF !important;
}
.btn-outline-info.btn-outline-lighten-3:hover {
  background-color : #36E9FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #36E9FF !important;
}

.border-info.border-lighten-3 {
  border : 1px solid #36E9FF !important;
}

.border-top-info.border-top-lighten-3 {
  border-top : 1px solid #36E9FF !important;
}

.border-bottom-info.border-bottom-lighten-3 {
  border-bottom : 1px solid #36E9FF !important;
}

.border-left-info.border-left-lighten-3 {
  border-left : 1px solid #36E9FF !important;
}

.border-right-info.border-right-lighten-3 {
  border-right : 1px solid #36E9FF !important;
}

.overlay-info.overlay-lighten-3 {
  background : #36E9FF;
  /* The Fallback */
  background : rgba(54, 233, 255, 0.6);
}

.info.lighten-2 {
  color : #1CE7FF !important;
}

.bg-info.bg-lighten-2 {
  background-color : #1CE7FF !important;
}

.btn-info.btn-lighten-2 {
  border-color : #00A1B5 !important;
  background-color : #1CE7FF !important;
}
.btn-info.btn-lighten-2:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-lighten-2:focus, .btn-info.btn-lighten-2:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-lighten-2 {
  border-color : #1CE7FF !important;
  color : #1CE7FF !important;
}
.btn-outline-info.btn-outline-lighten-2:hover {
  background-color : #1CE7FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #1CE7FF !important;
}

.border-info.border-lighten-2 {
  border : 1px solid #1CE7FF !important;
}

.border-top-info.border-top-lighten-2 {
  border-top : 1px solid #1CE7FF !important;
}

.border-bottom-info.border-bottom-lighten-2 {
  border-bottom : 1px solid #1CE7FF !important;
}

.border-left-info.border-left-lighten-2 {
  border-left : 1px solid #1CE7FF !important;
}

.border-right-info.border-right-lighten-2 {
  border-right : 1px solid #1CE7FF !important;
}

.overlay-info.overlay-lighten-2 {
  background : #1CE7FF;
  /* The Fallback */
  background : rgba(28, 231, 255, 0.6);
}

.info.lighten-1 {
  color : #03E4FF !important;
}

.bg-info.bg-lighten-1 {
  background-color : #03E4FF !important;
}

.btn-info.btn-lighten-1 {
  border-color : #00A1B5 !important;
  background-color : #03E4FF !important;
}
.btn-info.btn-lighten-1:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-lighten-1:focus, .btn-info.btn-lighten-1:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-lighten-1 {
  border-color : #03E4FF !important;
  color : #03E4FF !important;
}
.btn-outline-info.btn-outline-lighten-1:hover {
  background-color : #03E4FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #03E4FF !important;
}

.border-info.border-lighten-1 {
  border : 1px solid #03E4FF !important;
}

.border-top-info.border-top-lighten-1 {
  border-top : 1px solid #03E4FF !important;
}

.border-bottom-info.border-bottom-lighten-1 {
  border-bottom : 1px solid #03E4FF !important;
}

.border-left-info.border-left-lighten-1 {
  border-left : 1px solid #03E4FF !important;
}

.border-right-info.border-right-lighten-1 {
  border-right : 1px solid #03E4FF !important;
}

.overlay-info.overlay-lighten-1 {
  background : #03E4FF;
  /* The Fallback */
  background : rgba(3, 228, 255, 0.6);
}

.info {
  color : #00CFE8 !important;
}

.bg-info {
  background-color : #00CFE8 !important;
}
.bg-info .card-header, .bg-info .card-footer {
  background-color : transparent;
}

.alert-info {
  background : rgba(0, 207, 232, 0.2) !important;
  color : #00CFE8 !important;
}
.alert-info .alert-heading {
  box-shadow : rgba(0, 207, 232, 0.4) 0 6px 15px -7px;
}
.alert-info .alert-link {
  color : #00B8CF !important;
}

.bg-rgba-info {
  background : rgba(0, 207, 232, 0.15) !important;
}

.border-info {
  border : 1px solid #00CFE8 !important;
}

.border-top-info {
  border-top : 1px solid #00CFE8;
}

.border-bottom-info {
  border-bottom : 1px solid #00CFE8;
}

.border-left-info {
  border-left : 1px solid #00CFE8;
}

.border-right-info {
  border-right : 1px solid #00CFE8;
}

.bg-info.badge-glow, .border-info.badge-glow, .badge-info.badge-glow {
  box-shadow : 0 0 10px #00CFE8;
}

.badge.badge-info {
  background-color : #00CFE8;
}

.badge.badge-light-info {
  background-color : #B5F7FF;
  color : #00CFE8 !important;
  font-weight : 500;
}

.overlay-info {
  background : #00CFE8;
  /* The Fallback */
  background : rgba(0, 207, 232, 0.6);
}

.btn-info {
  border-color : #00A1B5 !important;
  background-color : #00CFE8 !important;
  color : #FFFFFF;
}
.btn-info:hover {
  border-color : #00B8CF !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #00CFE8;
}
.btn-info:focus, .btn-info:active {
  color : #FFFFFF !important;
}

.btn-flat-info {
  background-color : transparent;
  color : #00CFE8;
}
.btn-flat-info:hover {
  color : #00CFE8;
  background-color : rgba(0, 207, 232, 0.1);
}
.btn-flat-info:active, .btn-flat-info:focus {
  background-color : #00CFE8;
  color : #FFFFFF !important;
}

.btn-relief-info {
  background-color : #00CFE8;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-info:hover {
  color : #FFFFFF;
}
.btn-relief-info:active, .btn-relief-info:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-info {
  border : 1px solid #00CFE8;
  background-color : transparent;
  color : #00CFE8;
}
.btn-outline-info:hover {
  background-color : rgba(0, 207, 232, 0.08);
  color : #00CFE8;
}
.btn-outline-info:active {
  color : #FFFFFF !important;
}

.btn-info ~ .dropdown-menu .dropdown-item.active, .btn-outline-info ~ .dropdown-menu .dropdown-item.active, .btn-flat-info ~ .dropdown-menu .dropdown-item.active {
  background-color : #00CFE8;
  color : #FFFFFF;
}
.btn-info ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-info ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-info ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-info ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-info ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-info ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #00CFE8;
}

.dropdown.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:hover i {
  color : #00CFE8;
}

.dropdown.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-info ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-info {
  background-color : #00CFE8;
}

.pagination-info .page-item.active .page-link {
  background : #00CFE8;
  color : #FFFFFF;
}
.pagination-info .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-info .page-item .page-link:hover {
  color : #00CFE8;
}

.pagination-info .page-item.prev-item .page-link:hover, .pagination-info .page-item.next-item .page-link:hover {
  background : #00CFE8;
  color : #FFFFFF;
}

.nav-pill-info .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #00CFE8 !important;
}

.progress-bar-info {
  background-color : rgba(0, 207, 232, 0.1);
}
.progress-bar-info .progress-bar {
  background-color : #00CFE8;
}

.chip-info {
  background-color : #00CFE8 !important;
}
.chip-info .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-info .divider-text:before, .divider.divider-info .divider-text:after {
  border-color : #00CFE8 !important;
}

input[type='checkbox'].bg-info + .custom-control-label:before, input[type='radio'].bg-info + .custom-control-label:before {
  background-color : #00CFE8 !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #00CFE8 !important;
}

.vs-checkbox-info input:checked ~ .vs-checkbox {
  border-color : #00CFE8 !important;
}
.vs-checkbox-info input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #00CFE8 !important;
}

.vs-checkbox-info input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #00CFE8 !important;
}

.custom-switch-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #00CFE8 !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-info input:checked ~ .vs-radio .vs-radio--circle {
  background : #00CFE8 !important;
  box-shadow : 0 3px 12px 0 rgba(0, 207, 232, 0.4) !important;
}

.info.darken-1 {
  color : #00B8CF !important;
}

.bg-info.bg-darken-1 {
  background-color : #00B8CF !important;
}

.btn-info.btn-darken-1 {
  border-color : #00A1B5 !important;
  background-color : #00B8CF !important;
}
.btn-info.btn-darken-1:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-darken-1:focus, .btn-info.btn-darken-1:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-darken-1 {
  border-color : #00B8CF !important;
  color : #00B8CF !important;
}
.btn-outline-info.btn-outline-darken-1:hover {
  background-color : #00B8CF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #00B8CF !important;
}

.border-info.border-darken-1 {
  border : 1px solid #00B8CF !important;
}

.border-top-info.border-top-darken-1 {
  border-top : 1px solid #00B8CF !important;
}

.border-bottom-info.border-bottom-darken-1 {
  border-bottom : 1px solid #00B8CF !important;
}

.border-left-info.border-left-darken-1 {
  border-left : 1px solid #00B8CF !important;
}

.border-right-info.border-right-darken-1 {
  border-right : 1px solid #00B8CF !important;
}

.overlay-info.overlay-darken-1 {
  background : #00B8CF;
  /* The Fallback */
  background : rgba(0, 184, 207, 0.6);
}

.info.darken-2 {
  color : #00A1B5 !important;
}

.bg-info.bg-darken-2 {
  background-color : #00A1B5 !important;
}

.btn-info.btn-darken-2 {
  border-color : #00A1B5 !important;
  background-color : #00A1B5 !important;
}
.btn-info.btn-darken-2:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-darken-2:focus, .btn-info.btn-darken-2:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-darken-2 {
  border-color : #00A1B5 !important;
  color : #00A1B5 !important;
}
.btn-outline-info.btn-outline-darken-2:hover {
  background-color : #00A1B5 !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #00A1B5 !important;
}

.border-info.border-darken-2 {
  border : 1px solid #00A1B5 !important;
}

.border-top-info.border-top-darken-2 {
  border-top : 1px solid #00A1B5 !important;
}

.border-bottom-info.border-bottom-darken-2 {
  border-bottom : 1px solid #00A1B5 !important;
}

.border-left-info.border-left-darken-2 {
  border-left : 1px solid #00A1B5 !important;
}

.border-right-info.border-right-darken-2 {
  border-right : 1px solid #00A1B5 !important;
}

.overlay-info.overlay-darken-2 {
  background : #00A1B5;
  /* The Fallback */
  background : rgba(0, 161, 181, 0.6);
}

.info.darken-3 {
  color : #008B9C !important;
}

.bg-info.bg-darken-3 {
  background-color : #008B9C !important;
}

.btn-info.btn-darken-3 {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-darken-3:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-darken-3:focus, .btn-info.btn-darken-3:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-darken-3 {
  border-color : #008B9C !important;
  color : #008B9C !important;
}
.btn-outline-info.btn-outline-darken-3:hover {
  background-color : #008B9C !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #008B9C !important;
}

.border-info.border-darken-3 {
  border : 1px solid #008B9C !important;
}

.border-top-info.border-top-darken-3 {
  border-top : 1px solid #008B9C !important;
}

.border-bottom-info.border-bottom-darken-3 {
  border-bottom : 1px solid #008B9C !important;
}

.border-left-info.border-left-darken-3 {
  border-left : 1px solid #008B9C !important;
}

.border-right-info.border-right-darken-3 {
  border-right : 1px solid #008B9C !important;
}

.overlay-info.overlay-darken-3 {
  background : #008B9C;
  /* The Fallback */
  background : rgba(0, 139, 156, 0.6);
}

.info.darken-4 {
  color : #007482 !important;
}

.bg-info.bg-darken-4 {
  background-color : #007482 !important;
}

.btn-info.btn-darken-4 {
  border-color : #00A1B5 !important;
  background-color : #007482 !important;
}
.btn-info.btn-darken-4:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-darken-4:focus, .btn-info.btn-darken-4:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-darken-4 {
  border-color : #007482 !important;
  color : #007482 !important;
}
.btn-outline-info.btn-outline-darken-4:hover {
  background-color : #007482 !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #007482 !important;
}

.border-info.border-darken-4 {
  border : 1px solid #007482 !important;
}

.border-top-info.border-top-darken-4 {
  border-top : 1px solid #007482 !important;
}

.border-bottom-info.border-bottom-darken-4 {
  border-bottom : 1px solid #007482 !important;
}

.border-left-info.border-left-darken-4 {
  border-left : 1px solid #007482 !important;
}

.border-right-info.border-right-darken-4 {
  border-right : 1px solid #007482 !important;
}

.overlay-info.overlay-darken-4 {
  background : #007482;
  /* The Fallback */
  background : rgba(0, 116, 130, 0.6);
}

.info.accent-1 {
  color : #FEFFFF !important;
}

.bg-info.bg-accent-1 {
  background-color : #FEFFFF !important;
}

.btn-info.btn-accent-1 {
  border-color : #00A1B5 !important;
  background-color : #FEFFFF !important;
}
.btn-info.btn-accent-1:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-accent-1:focus, .btn-info.btn-accent-1:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-accent-1 {
  border-color : #FEFFFF !important;
  color : #FEFFFF !important;
}
.btn-outline-info.btn-outline-accent-1:hover {
  background-color : #FEFFFF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FEFFFF !important;
}

.border-info.border-accent-1 {
  border : 1px solid #FEFFFF !important;
}

.border-top-info.border-top-accent-1 {
  border-top : 1px solid #FEFFFF !important;
}

.border-bottom-info.border-bottom-accent-1 {
  border-bottom : 1px solid #FEFFFF !important;
}

.border-left-info.border-left-accent-1 {
  border-left : 1px solid #FEFFFF !important;
}

.border-right-info.border-right-accent-1 {
  border-right : 1px solid #FEFFFF !important;
}

.overlay-info.overlay-accent-1 {
  background : #FEFFFF;
  /* The Fallback */
  background : rgba(254, 255, 255, 0.6);
}

.info.accent-2 {
  color : #CBF5FF !important;
}

.bg-info.bg-accent-2 {
  background-color : #CBF5FF !important;
}

.btn-info.btn-accent-2 {
  border-color : #00A1B5 !important;
  background-color : #CBF5FF !important;
}
.btn-info.btn-accent-2:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-accent-2:focus, .btn-info.btn-accent-2:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-accent-2 {
  border-color : #CBF5FF !important;
  color : #CBF5FF !important;
}
.btn-outline-info.btn-outline-accent-2:hover {
  background-color : #CBF5FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #CBF5FF !important;
}

.border-info.border-accent-2 {
  border : 1px solid #CBF5FF !important;
}

.border-top-info.border-top-accent-2 {
  border-top : 1px solid #CBF5FF !important;
}

.border-bottom-info.border-bottom-accent-2 {
  border-bottom : 1px solid #CBF5FF !important;
}

.border-left-info.border-left-accent-2 {
  border-left : 1px solid #CBF5FF !important;
}

.border-right-info.border-right-accent-2 {
  border-right : 1px solid #CBF5FF !important;
}

.overlay-info.overlay-accent-2 {
  background : #CBF5FF;
  /* The Fallback */
  background : rgba(203, 245, 255, 0.6);
}

.info.accent-3 {
  color : #98ECFF !important;
}

.bg-info.bg-accent-3 {
  background-color : #98ECFF !important;
}

.btn-info.btn-accent-3 {
  border-color : #00A1B5 !important;
  background-color : #98ECFF !important;
}
.btn-info.btn-accent-3:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-accent-3:focus, .btn-info.btn-accent-3:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-accent-3 {
  border-color : #98ECFF !important;
  color : #98ECFF !important;
}
.btn-outline-info.btn-outline-accent-3:hover {
  background-color : #98ECFF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #98ECFF !important;
}

.border-info.border-accent-3 {
  border : 1px solid #98ECFF !important;
}

.border-top-info.border-top-accent-3 {
  border-top : 1px solid #98ECFF !important;
}

.border-bottom-info.border-bottom-accent-3 {
  border-bottom : 1px solid #98ECFF !important;
}

.border-left-info.border-left-accent-3 {
  border-left : 1px solid #98ECFF !important;
}

.border-right-info.border-right-accent-3 {
  border-right : 1px solid #98ECFF !important;
}

.overlay-info.overlay-accent-3 {
  background : #98ECFF;
  /* The Fallback */
  background : rgba(152, 236, 255, 0.6);
}

.info.accent-4 {
  color : #7FE7FF !important;
}

.bg-info.bg-accent-4 {
  background-color : #7FE7FF !important;
}

.btn-info.btn-accent-4 {
  border-color : #00A1B5 !important;
  background-color : #7FE7FF !important;
}
.btn-info.btn-accent-4:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-accent-4:focus, .btn-info.btn-accent-4:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-accent-4 {
  border-color : #7FE7FF !important;
  color : #7FE7FF !important;
}
.btn-outline-info.btn-outline-accent-4:hover {
  background-color : #7FE7FF !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #7FE7FF !important;
}

.border-info.border-accent-4 {
  border : 1px solid #7FE7FF !important;
}

.border-top-info.border-top-accent-4 {
  border-top : 1px solid #7FE7FF !important;
}

.border-bottom-info.border-bottom-accent-4 {
  border-bottom : 1px solid #7FE7FF !important;
}

.border-left-info.border-left-accent-4 {
  border-left : 1px solid #7FE7FF !important;
}

.border-right-info.border-right-accent-4 {
  border-right : 1px solid #7FE7FF !important;
}

.overlay-info.overlay-accent-4 {
  background : #7FE7FF;
  /* The Fallback */
  background : rgba(127, 231, 255, 0.6);
}

.info.gradient-bg {
  color : #640064 !important;
}

.bg-info.bg-gradient-bg {
  background-color : #640064 !important;
}

.btn-info.btn-gradient-bg {
  border-color : #00A1B5 !important;
  background-color : #640064 !important;
}
.btn-info.btn-gradient-bg:hover {
  border-color : #00A1B5 !important;
  background-color : #008B9C !important;
}
.btn-info.btn-gradient-bg:focus, .btn-info.btn-gradient-bg:active {
  border-color : #008B9C !important;
  background-color : #007482 !important;
}

.btn-outline-info.btn-outline-gradient-bg {
  border-color : #640064 !important;
  color : #640064 !important;
}
.btn-outline-info.btn-outline-gradient-bg:hover {
  background-color : #640064 !important;
}

input:focus ~ .bg-info {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #640064 !important;
}

.border-info.border-gradient-bg {
  border : 1px solid #640064 !important;
}

.border-top-info.border-top-gradient-bg {
  border-top : 1px solid #640064 !important;
}

.border-bottom-info.border-bottom-gradient-bg {
  border-bottom : 1px solid #640064 !important;
}

.border-left-info.border-left-gradient-bg {
  border-left : 1px solid #640064 !important;
}

.border-right-info.border-right-gradient-bg {
  border-right : 1px solid #640064 !important;
}

.overlay-info.overlay-gradient-bg {
  background : #640064;
  /* The Fallback */
  background : rgba(100, 0, 100, 0.6);
}

.warning.gradient-light {
  color : #FFF487 !important;
}

.bg-warning.bg-gradient-light {
  background-color : #FFF487 !important;
}

.btn-warning.btn-gradient-light {
  border-color : #FF8510 !important;
  background-color : #FFF487 !important;
}
.btn-warning.btn-gradient-light:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-gradient-light:focus, .btn-warning.btn-gradient-light:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-gradient-light {
  border-color : #FFF487 !important;
  color : #FFF487 !important;
}
.btn-outline-warning.btn-outline-gradient-light:hover {
  background-color : #FFF487 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFF487 !important;
}

.border-warning.border-gradient-light {
  border : 1px solid #FFF487 !important;
}

.border-top-warning.border-top-gradient-light {
  border-top : 1px solid #FFF487 !important;
}

.border-bottom-warning.border-bottom-gradient-light {
  border-bottom : 1px solid #FFF487 !important;
}

.border-left-warning.border-left-gradient-light {
  border-left : 1px solid #FFF487 !important;
}

.border-right-warning.border-right-gradient-light {
  border-right : 1px solid #FFF487 !important;
}

.overlay-warning.overlay-gradient-light {
  background : #FFF487;
  /* The Fallback */
  background : rgba(255, 244, 135, 0.6);
}

.warning.light-badge {
  color : #FFE0C3 !important;
}

.bg-warning.bg-light-badge {
  background-color : #FFE0C3 !important;
}

.btn-warning.btn-light-badge {
  border-color : #FF8510 !important;
  background-color : #FFE0C3 !important;
}
.btn-warning.btn-light-badge:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-light-badge:focus, .btn-warning.btn-light-badge:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-light-badge {
  border-color : #FFE0C3 !important;
  color : #FFE0C3 !important;
}
.btn-outline-warning.btn-outline-light-badge:hover {
  background-color : #FFE0C3 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFE0C3 !important;
}

.border-warning.border-light-badge {
  border : 1px solid #FFE0C3 !important;
}

.border-top-warning.border-top-light-badge {
  border-top : 1px solid #FFE0C3 !important;
}

.border-bottom-warning.border-bottom-light-badge {
  border-bottom : 1px solid #FFE0C3 !important;
}

.border-left-warning.border-left-light-badge {
  border-left : 1px solid #FFE0C3 !important;
}

.border-right-warning.border-right-light-badge {
  border-right : 1px solid #FFE0C3 !important;
}

.overlay-warning.overlay-light-badge {
  background : #FFE0C3;
  /* The Fallback */
  background : rgba(255, 224, 195, 0.6);
}

.warning.lighten-5 {
  color : #FFE0C3 !important;
}

.bg-warning.bg-lighten-5 {
  background-color : #FFE0C3 !important;
}

.btn-warning.btn-lighten-5 {
  border-color : #FF8510 !important;
  background-color : #FFE0C3 !important;
}
.btn-warning.btn-lighten-5:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-lighten-5:focus, .btn-warning.btn-lighten-5:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-lighten-5 {
  border-color : #FFE0C3 !important;
  color : #FFE0C3 !important;
}
.btn-outline-warning.btn-outline-lighten-5:hover {
  background-color : #FFE0C3 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFE0C3 !important;
}

.border-warning.border-lighten-5 {
  border : 1px solid #FFE0C3 !important;
}

.border-top-warning.border-top-lighten-5 {
  border-top : 1px solid #FFE0C3 !important;
}

.border-bottom-warning.border-bottom-lighten-5 {
  border-bottom : 1px solid #FFE0C3 !important;
}

.border-left-warning.border-left-lighten-5 {
  border-left : 1px solid #FFE0C3 !important;
}

.border-right-warning.border-right-lighten-5 {
  border-right : 1px solid #FFE0C3 !important;
}

.overlay-warning.overlay-lighten-5 {
  background : #FFE0C3;
  /* The Fallback */
  background : rgba(255, 224, 195, 0.6);
}

.warning.lighten-4 {
  color : #FFD3A9 !important;
}

.bg-warning.bg-lighten-4 {
  background-color : #FFD3A9 !important;
}

.btn-warning.btn-lighten-4 {
  border-color : #FF8510 !important;
  background-color : #FFD3A9 !important;
}
.btn-warning.btn-lighten-4:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-lighten-4:focus, .btn-warning.btn-lighten-4:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-lighten-4 {
  border-color : #FFD3A9 !important;
  color : #FFD3A9 !important;
}
.btn-outline-warning.btn-outline-lighten-4:hover {
  background-color : #FFD3A9 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFD3A9 !important;
}

.border-warning.border-lighten-4 {
  border : 1px solid #FFD3A9 !important;
}

.border-top-warning.border-top-lighten-4 {
  border-top : 1px solid #FFD3A9 !important;
}

.border-bottom-warning.border-bottom-lighten-4 {
  border-bottom : 1px solid #FFD3A9 !important;
}

.border-left-warning.border-left-lighten-4 {
  border-left : 1px solid #FFD3A9 !important;
}

.border-right-warning.border-right-lighten-4 {
  border-right : 1px solid #FFD3A9 !important;
}

.overlay-warning.overlay-lighten-4 {
  background : #FFD3A9;
  /* The Fallback */
  background : rgba(255, 211, 169, 0.6);
}

.warning.lighten-3 {
  color : #FFC690 !important;
}

.bg-warning.bg-lighten-3 {
  background-color : #FFC690 !important;
}

.btn-warning.btn-lighten-3 {
  border-color : #FF8510 !important;
  background-color : #FFC690 !important;
}
.btn-warning.btn-lighten-3:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-lighten-3:focus, .btn-warning.btn-lighten-3:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-lighten-3 {
  border-color : #FFC690 !important;
  color : #FFC690 !important;
}
.btn-outline-warning.btn-outline-lighten-3:hover {
  background-color : #FFC690 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFC690 !important;
}

.border-warning.border-lighten-3 {
  border : 1px solid #FFC690 !important;
}

.border-top-warning.border-top-lighten-3 {
  border-top : 1px solid #FFC690 !important;
}

.border-bottom-warning.border-bottom-lighten-3 {
  border-bottom : 1px solid #FFC690 !important;
}

.border-left-warning.border-left-lighten-3 {
  border-left : 1px solid #FFC690 !important;
}

.border-right-warning.border-right-lighten-3 {
  border-right : 1px solid #FFC690 !important;
}

.overlay-warning.overlay-lighten-3 {
  background : #FFC690;
  /* The Fallback */
  background : rgba(255, 198, 144, 0.6);
}

.warning.lighten-2 {
  color : #FFB976 !important;
}

.bg-warning.bg-lighten-2 {
  background-color : #FFB976 !important;
}

.btn-warning.btn-lighten-2 {
  border-color : #FF8510 !important;
  background-color : #FFB976 !important;
}
.btn-warning.btn-lighten-2:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-lighten-2:focus, .btn-warning.btn-lighten-2:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-lighten-2 {
  border-color : #FFB976 !important;
  color : #FFB976 !important;
}
.btn-outline-warning.btn-outline-lighten-2:hover {
  background-color : #FFB976 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFB976 !important;
}

.border-warning.border-lighten-2 {
  border : 1px solid #FFB976 !important;
}

.border-top-warning.border-top-lighten-2 {
  border-top : 1px solid #FFB976 !important;
}

.border-bottom-warning.border-bottom-lighten-2 {
  border-bottom : 1px solid #FFB976 !important;
}

.border-left-warning.border-left-lighten-2 {
  border-left : 1px solid #FFB976 !important;
}

.border-right-warning.border-right-lighten-2 {
  border-right : 1px solid #FFB976 !important;
}

.overlay-warning.overlay-lighten-2 {
  background : #FFB976;
  /* The Fallback */
  background : rgba(255, 185, 118, 0.6);
}

.warning.lighten-1 {
  color : #FFAC5D !important;
}

.bg-warning.bg-lighten-1 {
  background-color : #FFAC5D !important;
}

.btn-warning.btn-lighten-1 {
  border-color : #FF8510 !important;
  background-color : #FFAC5D !important;
}
.btn-warning.btn-lighten-1:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-lighten-1:focus, .btn-warning.btn-lighten-1:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-lighten-1 {
  border-color : #FFAC5D !important;
  color : #FFAC5D !important;
}
.btn-outline-warning.btn-outline-lighten-1:hover {
  background-color : #FFAC5D !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFAC5D !important;
}

.border-warning.border-lighten-1 {
  border : 1px solid #FFAC5D !important;
}

.border-top-warning.border-top-lighten-1 {
  border-top : 1px solid #FFAC5D !important;
}

.border-bottom-warning.border-bottom-lighten-1 {
  border-bottom : 1px solid #FFAC5D !important;
}

.border-left-warning.border-left-lighten-1 {
  border-left : 1px solid #FFAC5D !important;
}

.border-right-warning.border-right-lighten-1 {
  border-right : 1px solid #FFAC5D !important;
}

.overlay-warning.overlay-lighten-1 {
  background : #FFAC5D;
  /* The Fallback */
  background : rgba(255, 172, 93, 0.6);
}

.warning {
  color : #FF9F43 !important;
}

.bg-warning {
  background-color : #FF9F43 !important;
}
.bg-warning .card-header, .bg-warning .card-footer {
  background-color : transparent;
}

.alert-warning {
  background : rgba(255, 159, 67, 0.2) !important;
  color : #FF9F43 !important;
}
.alert-warning .alert-heading {
  box-shadow : rgba(255, 159, 67, 0.4) 0 6px 15px -7px;
}
.alert-warning .alert-link {
  color : #FF922A !important;
}

.bg-rgba-warning {
  background : rgba(255, 159, 67, 0.15) !important;
}

.border-warning {
  border : 1px solid #FF9F43 !important;
}

.border-top-warning {
  border-top : 1px solid #FF9F43;
}

.border-bottom-warning {
  border-bottom : 1px solid #FF9F43;
}

.border-left-warning {
  border-left : 1px solid #FF9F43;
}

.border-right-warning {
  border-right : 1px solid #FF9F43;
}

.bg-warning.badge-glow, .border-warning.badge-glow, .badge-warning.badge-glow {
  box-shadow : 0 0 10px #FF9F43;
}

.badge.badge-warning {
  background-color : #FF9F43;
}

.badge.badge-light-warning {
  background-color : #FFE0C3;
  color : #FF9F43 !important;
  font-weight : 500;
}

.overlay-warning {
  background : #FF9F43;
  /* The Fallback */
  background : rgba(255, 159, 67, 0.6);
}

.btn-warning {
  border-color : #FF8510 !important;
  background-color : #FF9F43 !important;
  color : #FFFFFF;
}
.btn-warning:hover {
  border-color : #FF922A !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #FF9F43;
}
.btn-warning:focus, .btn-warning:active {
  color : #FFFFFF !important;
}

.btn-flat-warning {
  background-color : transparent;
  color : #FF9F43;
}
.btn-flat-warning:hover {
  color : #FF9F43;
  background-color : rgba(255, 159, 67, 0.1);
}
.btn-flat-warning:active, .btn-flat-warning:focus {
  background-color : #FF9F43;
  color : #FFFFFF !important;
}

.btn-relief-warning {
  background-color : #FF9F43;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-warning:hover {
  color : #FFFFFF;
}
.btn-relief-warning:active, .btn-relief-warning:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-warning {
  border : 1px solid #FF9F43;
  background-color : transparent;
  color : #FF9F43;
}
.btn-outline-warning:hover {
  background-color : rgba(255, 159, 67, 0.08);
  color : #FF9F43;
}
.btn-outline-warning:active {
  color : #FFFFFF !important;
}

.btn-warning ~ .dropdown-menu .dropdown-item.active, .btn-outline-warning ~ .dropdown-menu .dropdown-item.active, .btn-flat-warning ~ .dropdown-menu .dropdown-item.active {
  background-color : #FF9F43;
  color : #FFFFFF;
}
.btn-warning ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-warning ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-warning ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-warning ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-warning ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-warning ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #FF9F43;
}

.dropdown.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:hover i {
  color : #FF9F43;
}

.dropdown.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-warning ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-warning {
  background-color : #FF9F43;
}

.pagination-warning .page-item.active .page-link {
  background : #FF9F43;
  color : #FFFFFF;
}
.pagination-warning .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-warning .page-item .page-link:hover {
  color : #FF9F43;
}

.pagination-warning .page-item.prev-item .page-link:hover, .pagination-warning .page-item.next-item .page-link:hover {
  background : #FF9F43;
  color : #FFFFFF;
}

.nav-pill-warning .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #FF9F43 !important;
}

.progress-bar-warning {
  background-color : rgba(255, 159, 67, 0.1);
}
.progress-bar-warning .progress-bar {
  background-color : #FF9F43;
}

.chip-warning {
  background-color : #FF9F43 !important;
}
.chip-warning .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-warning .divider-text:before, .divider.divider-warning .divider-text:after {
  border-color : #FF9F43 !important;
}

input[type='checkbox'].bg-warning + .custom-control-label:before, input[type='radio'].bg-warning + .custom-control-label:before {
  background-color : #FF9F43 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FF9F43 !important;
}

.vs-checkbox-warning input:checked ~ .vs-checkbox {
  border-color : #FF9F43 !important;
}
.vs-checkbox-warning input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #FF9F43 !important;
}

.vs-checkbox-warning input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #FF9F43 !important;
}

.custom-switch-warning .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #FF9F43 !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-warning input:checked ~ .vs-radio .vs-radio--circle {
  background : #FF9F43 !important;
  box-shadow : 0 3px 12px 0 rgba(255, 159, 67, 0.4) !important;
}

.warning.darken-1 {
  color : #FF922A !important;
}

.bg-warning.bg-darken-1 {
  background-color : #FF922A !important;
}

.btn-warning.btn-darken-1 {
  border-color : #FF8510 !important;
  background-color : #FF922A !important;
}
.btn-warning.btn-darken-1:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-darken-1:focus, .btn-warning.btn-darken-1:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-darken-1 {
  border-color : #FF922A !important;
  color : #FF922A !important;
}
.btn-outline-warning.btn-outline-darken-1:hover {
  background-color : #FF922A !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FF922A !important;
}

.border-warning.border-darken-1 {
  border : 1px solid #FF922A !important;
}

.border-top-warning.border-top-darken-1 {
  border-top : 1px solid #FF922A !important;
}

.border-bottom-warning.border-bottom-darken-1 {
  border-bottom : 1px solid #FF922A !important;
}

.border-left-warning.border-left-darken-1 {
  border-left : 1px solid #FF922A !important;
}

.border-right-warning.border-right-darken-1 {
  border-right : 1px solid #FF922A !important;
}

.overlay-warning.overlay-darken-1 {
  background : #FF922A;
  /* The Fallback */
  background : rgba(255, 146, 42, 0.6);
}

.warning.darken-2 {
  color : #FF8510 !important;
}

.bg-warning.bg-darken-2 {
  background-color : #FF8510 !important;
}

.btn-warning.btn-darken-2 {
  border-color : #FF8510 !important;
  background-color : #FF8510 !important;
}
.btn-warning.btn-darken-2:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-darken-2:focus, .btn-warning.btn-darken-2:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-darken-2 {
  border-color : #FF8510 !important;
  color : #FF8510 !important;
}
.btn-outline-warning.btn-outline-darken-2:hover {
  background-color : #FF8510 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FF8510 !important;
}

.border-warning.border-darken-2 {
  border : 1px solid #FF8510 !important;
}

.border-top-warning.border-top-darken-2 {
  border-top : 1px solid #FF8510 !important;
}

.border-bottom-warning.border-bottom-darken-2 {
  border-bottom : 1px solid #FF8510 !important;
}

.border-left-warning.border-left-darken-2 {
  border-left : 1px solid #FF8510 !important;
}

.border-right-warning.border-right-darken-2 {
  border-right : 1px solid #FF8510 !important;
}

.overlay-warning.overlay-darken-2 {
  background : #FF8510;
  /* The Fallback */
  background : rgba(255, 133, 16, 0.6);
}

.warning.darken-3 {
  color : #F67800 !important;
}

.bg-warning.bg-darken-3 {
  background-color : #F67800 !important;
}

.btn-warning.btn-darken-3 {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-darken-3:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-darken-3:focus, .btn-warning.btn-darken-3:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-darken-3 {
  border-color : #F67800 !important;
  color : #F67800 !important;
}
.btn-outline-warning.btn-outline-darken-3:hover {
  background-color : #F67800 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F67800 !important;
}

.border-warning.border-darken-3 {
  border : 1px solid #F67800 !important;
}

.border-top-warning.border-top-darken-3 {
  border-top : 1px solid #F67800 !important;
}

.border-bottom-warning.border-bottom-darken-3 {
  border-bottom : 1px solid #F67800 !important;
}

.border-left-warning.border-left-darken-3 {
  border-left : 1px solid #F67800 !important;
}

.border-right-warning.border-right-darken-3 {
  border-right : 1px solid #F67800 !important;
}

.overlay-warning.overlay-darken-3 {
  background : #F67800;
  /* The Fallback */
  background : rgba(246, 120, 0, 0.6);
}

.warning.darken-4 {
  color : #DC6C00 !important;
}

.bg-warning.bg-darken-4 {
  background-color : #DC6C00 !important;
}

.btn-warning.btn-darken-4 {
  border-color : #FF8510 !important;
  background-color : #DC6C00 !important;
}
.btn-warning.btn-darken-4:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-darken-4:focus, .btn-warning.btn-darken-4:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-darken-4 {
  border-color : #DC6C00 !important;
  color : #DC6C00 !important;
}
.btn-outline-warning.btn-outline-darken-4:hover {
  background-color : #DC6C00 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #DC6C00 !important;
}

.border-warning.border-darken-4 {
  border : 1px solid #DC6C00 !important;
}

.border-top-warning.border-top-darken-4 {
  border-top : 1px solid #DC6C00 !important;
}

.border-bottom-warning.border-bottom-darken-4 {
  border-bottom : 1px solid #DC6C00 !important;
}

.border-left-warning.border-left-darken-4 {
  border-left : 1px solid #DC6C00 !important;
}

.border-right-warning.border-right-darken-4 {
  border-right : 1px solid #DC6C00 !important;
}

.overlay-warning.overlay-darken-4 {
  background : #DC6C00;
  /* The Fallback */
  background : rgba(220, 108, 0, 0.6);
}

.warning.accent-1 {
  color : #FFF5EF !important;
}

.bg-warning.bg-accent-1 {
  background-color : #FFF5EF !important;
}

.btn-warning.btn-accent-1 {
  border-color : #FF8510 !important;
  background-color : #FFF5EF !important;
}
.btn-warning.btn-accent-1:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-accent-1:focus, .btn-warning.btn-accent-1:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-accent-1 {
  border-color : #FFF5EF !important;
  color : #FFF5EF !important;
}
.btn-outline-warning.btn-outline-accent-1:hover {
  background-color : #FFF5EF !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFF5EF !important;
}

.border-warning.border-accent-1 {
  border : 1px solid #FFF5EF !important;
}

.border-top-warning.border-top-accent-1 {
  border-top : 1px solid #FFF5EF !important;
}

.border-bottom-warning.border-bottom-accent-1 {
  border-bottom : 1px solid #FFF5EF !important;
}

.border-left-warning.border-left-accent-1 {
  border-left : 1px solid #FFF5EF !important;
}

.border-right-warning.border-right-accent-1 {
  border-right : 1px solid #FFF5EF !important;
}

.overlay-warning.overlay-accent-1 {
  background : #FFF5EF;
  /* The Fallback */
  background : rgba(255, 245, 239, 0.6);
}

.warning.accent-2 {
  color : #FFE5D8 !important;
}

.bg-warning.bg-accent-2 {
  background-color : #FFE5D8 !important;
}

.btn-warning.btn-accent-2 {
  border-color : #FF8510 !important;
  background-color : #FFE5D8 !important;
}
.btn-warning.btn-accent-2:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-accent-2:focus, .btn-warning.btn-accent-2:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-accent-2 {
  border-color : #FFE5D8 !important;
  color : #FFE5D8 !important;
}
.btn-outline-warning.btn-outline-accent-2:hover {
  background-color : #FFE5D8 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFE5D8 !important;
}

.border-warning.border-accent-2 {
  border : 1px solid #FFE5D8 !important;
}

.border-top-warning.border-top-accent-2 {
  border-top : 1px solid #FFE5D8 !important;
}

.border-bottom-warning.border-bottom-accent-2 {
  border-bottom : 1px solid #FFE5D8 !important;
}

.border-left-warning.border-left-accent-2 {
  border-left : 1px solid #FFE5D8 !important;
}

.border-right-warning.border-right-accent-2 {
  border-right : 1px solid #FFE5D8 !important;
}

.overlay-warning.overlay-accent-2 {
  background : #FFE5D8;
  /* The Fallback */
  background : rgba(255, 229, 216, 0.6);
}

.warning.accent-3 {
  color : #FFF6F3 !important;
}

.bg-warning.bg-accent-3 {
  background-color : #FFF6F3 !important;
}

.btn-warning.btn-accent-3 {
  border-color : #FF8510 !important;
  background-color : #FFF6F3 !important;
}
.btn-warning.btn-accent-3:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-accent-3:focus, .btn-warning.btn-accent-3:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-accent-3 {
  border-color : #FFF6F3 !important;
  color : #FFF6F3 !important;
}
.btn-outline-warning.btn-outline-accent-3:hover {
  background-color : #FFF6F3 !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFF6F3 !important;
}

.border-warning.border-accent-3 {
  border : 1px solid #FFF6F3 !important;
}

.border-top-warning.border-top-accent-3 {
  border-top : 1px solid #FFF6F3 !important;
}

.border-bottom-warning.border-bottom-accent-3 {
  border-bottom : 1px solid #FFF6F3 !important;
}

.border-left-warning.border-left-accent-3 {
  border-left : 1px solid #FFF6F3 !important;
}

.border-right-warning.border-right-accent-3 {
  border-right : 1px solid #FFF6F3 !important;
}

.overlay-warning.overlay-accent-3 {
  background : #FFF6F3;
  /* The Fallback */
  background : rgba(255, 246, 243, 0.6);
}

.warning.accent-4 {
  color : #FFE3DA !important;
}

.bg-warning.bg-accent-4 {
  background-color : #FFE3DA !important;
}

.btn-warning.btn-accent-4 {
  border-color : #FF8510 !important;
  background-color : #FFE3DA !important;
}
.btn-warning.btn-accent-4:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-accent-4:focus, .btn-warning.btn-accent-4:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-accent-4 {
  border-color : #FFE3DA !important;
  color : #FFE3DA !important;
}
.btn-outline-warning.btn-outline-accent-4:hover {
  background-color : #FFE3DA !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFE3DA !important;
}

.border-warning.border-accent-4 {
  border : 1px solid #FFE3DA !important;
}

.border-top-warning.border-top-accent-4 {
  border-top : 1px solid #FFE3DA !important;
}

.border-bottom-warning.border-bottom-accent-4 {
  border-bottom : 1px solid #FFE3DA !important;
}

.border-left-warning.border-left-accent-4 {
  border-left : 1px solid #FFE3DA !important;
}

.border-right-warning.border-right-accent-4 {
  border-right : 1px solid #FFE3DA !important;
}

.overlay-warning.overlay-accent-4 {
  background : #FFE3DA;
  /* The Fallback */
  background : rgba(255, 227, 218, 0.6);
}

.warning.gradient-bg {
  color : #FAFAFA !important;
}

.bg-warning.bg-gradient-bg {
  background-color : #FAFAFA !important;
}

.btn-warning.btn-gradient-bg {
  border-color : #FF8510 !important;
  background-color : #FAFAFA !important;
}
.btn-warning.btn-gradient-bg:hover {
  border-color : #FF8510 !important;
  background-color : #F67800 !important;
}
.btn-warning.btn-gradient-bg:focus, .btn-warning.btn-gradient-bg:active {
  border-color : #F67800 !important;
  background-color : #DC6C00 !important;
}

.btn-outline-warning.btn-outline-gradient-bg {
  border-color : #FAFAFA !important;
  color : #FAFAFA !important;
}
.btn-outline-warning.btn-outline-gradient-bg:hover {
  background-color : #FAFAFA !important;
}

input:focus ~ .bg-warning {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FAFAFA !important;
}

.border-warning.border-gradient-bg {
  border : 1px solid #FAFAFA !important;
}

.border-top-warning.border-top-gradient-bg {
  border-top : 1px solid #FAFAFA !important;
}

.border-bottom-warning.border-bottom-gradient-bg {
  border-bottom : 1px solid #FAFAFA !important;
}

.border-left-warning.border-left-gradient-bg {
  border-left : 1px solid #FAFAFA !important;
}

.border-right-warning.border-right-gradient-bg {
  border-right : 1px solid #FAFAFA !important;
}

.overlay-warning.overlay-gradient-bg {
  background : #FAFAFA;
  /* The Fallback */
  background : rgba(250, 250, 250, 0.6);
}

.danger.gradient-light {
  color : #F9B789 !important;
}

.bg-danger.bg-gradient-light {
  background-color : #F9B789 !important;
}

.btn-danger.btn-gradient-light {
  border-color : #E42728 !important;
  background-color : #F9B789 !important;
}
.btn-danger.btn-gradient-light:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-gradient-light:focus, .btn-danger.btn-gradient-light:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-gradient-light {
  border-color : #F9B789 !important;
  color : #F9B789 !important;
}
.btn-outline-danger.btn-outline-gradient-light:hover {
  background-color : #F9B789 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F9B789 !important;
}

.border-danger.border-gradient-light {
  border : 1px solid #F9B789 !important;
}

.border-top-danger.border-top-gradient-light {
  border-top : 1px solid #F9B789 !important;
}

.border-bottom-danger.border-bottom-gradient-light {
  border-bottom : 1px solid #F9B789 !important;
}

.border-left-danger.border-left-gradient-light {
  border-left : 1px solid #F9B789 !important;
}

.border-right-danger.border-right-gradient-light {
  border-right : 1px solid #F9B789 !important;
}

.overlay-danger.overlay-gradient-light {
  background : #F9B789;
  /* The Fallback */
  background : rgba(249, 183, 137, 0.6);
}

.danger.light-badge {
  color : #FBDCDC !important;
}

.bg-danger.bg-light-badge {
  background-color : #FBDCDC !important;
}

.btn-danger.btn-light-badge {
  border-color : #E42728 !important;
  background-color : #FBDCDC !important;
}
.btn-danger.btn-light-badge:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-light-badge:focus, .btn-danger.btn-light-badge:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-light-badge {
  border-color : #FBDCDC !important;
  color : #FBDCDC !important;
}
.btn-outline-danger.btn-outline-light-badge:hover {
  background-color : #FBDCDC !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FBDCDC !important;
}

.border-danger.border-light-badge {
  border : 1px solid #FBDCDC !important;
}

.border-top-danger.border-top-light-badge {
  border-top : 1px solid #FBDCDC !important;
}

.border-bottom-danger.border-bottom-light-badge {
  border-bottom : 1px solid #FBDCDC !important;
}

.border-left-danger.border-left-light-badge {
  border-left : 1px solid #FBDCDC !important;
}

.border-right-danger.border-right-light-badge {
  border-right : 1px solid #FBDCDC !important;
}

.overlay-danger.overlay-light-badge {
  background : #FBDCDC;
  /* The Fallback */
  background : rgba(251, 220, 220, 0.6);
}

.danger.lighten-5 {
  color : #F8C6C6 !important;
}

.bg-danger.bg-lighten-5 {
  background-color : #F8C6C6 !important;
}

.btn-danger.btn-lighten-5 {
  border-color : #E42728 !important;
  background-color : #F8C6C6 !important;
}
.btn-danger.btn-lighten-5:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-lighten-5:focus, .btn-danger.btn-lighten-5:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-lighten-5 {
  border-color : #F8C6C6 !important;
  color : #F8C6C6 !important;
}
.btn-outline-danger.btn-outline-lighten-5:hover {
  background-color : #F8C6C6 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F8C6C6 !important;
}

.border-danger.border-lighten-5 {
  border : 1px solid #F8C6C6 !important;
}

.border-top-danger.border-top-lighten-5 {
  border-top : 1px solid #F8C6C6 !important;
}

.border-bottom-danger.border-bottom-lighten-5 {
  border-bottom : 1px solid #F8C6C6 !important;
}

.border-left-danger.border-left-lighten-5 {
  border-left : 1px solid #F8C6C6 !important;
}

.border-right-danger.border-right-lighten-5 {
  border-right : 1px solid #F8C6C6 !important;
}

.overlay-danger.overlay-lighten-5 {
  background : #F8C6C6;
  /* The Fallback */
  background : rgba(248, 198, 198, 0.6);
}

.danger.lighten-4 {
  color : #F5AFAF !important;
}

.bg-danger.bg-lighten-4 {
  background-color : #F5AFAF !important;
}

.btn-danger.btn-lighten-4 {
  border-color : #E42728 !important;
  background-color : #F5AFAF !important;
}
.btn-danger.btn-lighten-4:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-lighten-4:focus, .btn-danger.btn-lighten-4:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-lighten-4 {
  border-color : #F5AFAF !important;
  color : #F5AFAF !important;
}
.btn-outline-danger.btn-outline-lighten-4:hover {
  background-color : #F5AFAF !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F5AFAF !important;
}

.border-danger.border-lighten-4 {
  border : 1px solid #F5AFAF !important;
}

.border-top-danger.border-top-lighten-4 {
  border-top : 1px solid #F5AFAF !important;
}

.border-bottom-danger.border-bottom-lighten-4 {
  border-bottom : 1px solid #F5AFAF !important;
}

.border-left-danger.border-left-lighten-4 {
  border-left : 1px solid #F5AFAF !important;
}

.border-right-danger.border-right-lighten-4 {
  border-right : 1px solid #F5AFAF !important;
}

.overlay-danger.overlay-lighten-4 {
  background : #F5AFAF;
  /* The Fallback */
  background : rgba(245, 175, 175, 0.6);
}

.danger.lighten-3 {
  color : #F29899 !important;
}

.bg-danger.bg-lighten-3 {
  background-color : #F29899 !important;
}

.btn-danger.btn-lighten-3 {
  border-color : #E42728 !important;
  background-color : #F29899 !important;
}
.btn-danger.btn-lighten-3:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-lighten-3:focus, .btn-danger.btn-lighten-3:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-lighten-3 {
  border-color : #F29899 !important;
  color : #F29899 !important;
}
.btn-outline-danger.btn-outline-lighten-3:hover {
  background-color : #F29899 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F29899 !important;
}

.border-danger.border-lighten-3 {
  border : 1px solid #F29899 !important;
}

.border-top-danger.border-top-lighten-3 {
  border-top : 1px solid #F29899 !important;
}

.border-bottom-danger.border-bottom-lighten-3 {
  border-bottom : 1px solid #F29899 !important;
}

.border-left-danger.border-left-lighten-3 {
  border-left : 1px solid #F29899 !important;
}

.border-right-danger.border-right-lighten-3 {
  border-right : 1px solid #F29899 !important;
}

.overlay-danger.overlay-lighten-3 {
  background : #F29899;
  /* The Fallback */
  background : rgba(242, 152, 153, 0.6);
}

.danger.lighten-2 {
  color : #F08182 !important;
}

.bg-danger.bg-lighten-2 {
  background-color : #F08182 !important;
}

.btn-danger.btn-lighten-2 {
  border-color : #E42728 !important;
  background-color : #F08182 !important;
}
.btn-danger.btn-lighten-2:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-lighten-2:focus, .btn-danger.btn-lighten-2:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-lighten-2 {
  border-color : #F08182 !important;
  color : #F08182 !important;
}
.btn-outline-danger.btn-outline-lighten-2:hover {
  background-color : #F08182 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #F08182 !important;
}

.border-danger.border-lighten-2 {
  border : 1px solid #F08182 !important;
}

.border-top-danger.border-top-lighten-2 {
  border-top : 1px solid #F08182 !important;
}

.border-bottom-danger.border-bottom-lighten-2 {
  border-bottom : 1px solid #F08182 !important;
}

.border-left-danger.border-left-lighten-2 {
  border-left : 1px solid #F08182 !important;
}

.border-right-danger.border-right-lighten-2 {
  border-right : 1px solid #F08182 !important;
}

.overlay-danger.overlay-lighten-2 {
  background : #F08182;
  /* The Fallback */
  background : rgba(240, 129, 130, 0.6);
}

.danger.lighten-1 {
  color : #ED6B6C !important;
}

.bg-danger.bg-lighten-1 {
  background-color : #ED6B6C !important;
}

.btn-danger.btn-lighten-1 {
  border-color : #E42728 !important;
  background-color : #ED6B6C !important;
}
.btn-danger.btn-lighten-1:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-lighten-1:focus, .btn-danger.btn-lighten-1:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-lighten-1 {
  border-color : #ED6B6C !important;
  color : #ED6B6C !important;
}
.btn-outline-danger.btn-outline-lighten-1:hover {
  background-color : #ED6B6C !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #ED6B6C !important;
}

.border-danger.border-lighten-1 {
  border : 1px solid #ED6B6C !important;
}

.border-top-danger.border-top-lighten-1 {
  border-top : 1px solid #ED6B6C !important;
}

.border-bottom-danger.border-bottom-lighten-1 {
  border-bottom : 1px solid #ED6B6C !important;
}

.border-left-danger.border-left-lighten-1 {
  border-left : 1px solid #ED6B6C !important;
}

.border-right-danger.border-right-lighten-1 {
  border-right : 1px solid #ED6B6C !important;
}

.overlay-danger.overlay-lighten-1 {
  background : #ED6B6C;
  /* The Fallback */
  background : rgba(237, 107, 108, 0.6);
}

.danger {
  color : #EA5455 !important;
}

.bg-danger {
  background-color : #EA5455 !important;
}
.bg-danger .card-header, .bg-danger .card-footer {
  background-color : transparent;
}

.alert-danger {
  background : rgba(234, 84, 85, 0.2) !important;
  color : #EA5455 !important;
}
.alert-danger .alert-heading {
  box-shadow : rgba(234, 84, 85, 0.4) 0 6px 15px -7px;
}
.alert-danger .alert-link {
  color : #E73D3E !important;
}

.bg-rgba-danger {
  background : rgba(234, 84, 85, 0.15) !important;
}

.border-danger {
  border : 1px solid #EA5455 !important;
}

.border-top-danger {
  border-top : 1px solid #EA5455;
}

.border-bottom-danger {
  border-bottom : 1px solid #EA5455;
}

.border-left-danger {
  border-left : 1px solid #EA5455;
}

.border-right-danger {
  border-right : 1px solid #EA5455;
}

.bg-danger.badge-glow, .border-danger.badge-glow, .badge-danger.badge-glow {
  box-shadow : 0 0 10px #EA5455;
}

.badge.badge-danger {
  background-color : #EA5455;
}

.badge.badge-light-danger {
  background-color : #FBDCDC;
  color : #EA5455 !important;
  font-weight : 500;
}

.overlay-danger {
  background : #EA5455;
  /* The Fallback */
  background : rgba(234, 84, 85, 0.6);
}

.btn-danger {
  border-color : #E42728 !important;
  background-color : #EA5455 !important;
  color : #FFFFFF;
}
.btn-danger:hover {
  border-color : #E73D3E !important;
  color : #FFFFFF !important;
  box-shadow : 0 8px 25px -8px #EA5455;
}
.btn-danger:focus, .btn-danger:active {
  color : #FFFFFF !important;
}

.btn-flat-danger {
  background-color : transparent;
  color : #EA5455;
}
.btn-flat-danger:hover {
  color : #EA5455;
  background-color : rgba(234, 84, 85, 0.1);
}
.btn-flat-danger:active, .btn-flat-danger:focus {
  background-color : #EA5455;
  color : #FFFFFF !important;
}

.btn-relief-danger {
  background-color : #EA5455;
  box-shadow : inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color : #FFFFFF;
}
.btn-relief-danger:hover {
  color : #FFFFFF;
}
.btn-relief-danger:active, .btn-relief-danger:focus {
  outline : none;
  -webkit-transform : translateY(3px);
      -ms-transform : translateY(3px);
          transform : translateY(3px);
}

.btn-outline-danger {
  border : 1px solid #EA5455;
  background-color : transparent;
  color : #EA5455;
}
.btn-outline-danger:hover {
  background-color : rgba(234, 84, 85, 0.08);
  color : #EA5455;
}
.btn-outline-danger:active {
  color : #FFFFFF !important;
}

.btn-danger ~ .dropdown-menu .dropdown-item.active, .btn-outline-danger ~ .dropdown-menu .dropdown-item.active, .btn-flat-danger ~ .dropdown-menu .dropdown-item.active {
  background-color : #EA5455;
  color : #FFFFFF;
}
.btn-danger ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-danger ~ .dropdown-menu .dropdown-item.active:hover, .btn-flat-danger ~ .dropdown-menu .dropdown-item.active:hover {
  color : #FFFFFF;
}

.btn-danger ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-outline-danger ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover, .btn-flat-danger ~ .dropdown-menu .dropdown-item:not(.active):not(:active):hover {
  color : #EA5455;
}

.dropdown.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i, .dropup.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i, .dropright.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i, .dropleft.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:hover i {
  color : #EA5455;
}

.dropdown.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i, .dropup.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i, .dropright.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i, .dropleft.dropdown-icon-wrapper .btn-flat-danger ~ .dropdown-menu .dropdown-item:active i {
  color : #FFFFFF;
}

.bullet.bullet-danger {
  background-color : #EA5455;
}

.pagination-danger .page-item.active .page-link {
  background : #EA5455;
  color : #FFFFFF;
}
.pagination-danger .page-item.active .page-link:hover {
  color : #FFFFFF;
}

.pagination-danger .page-item .page-link:hover {
  color : #EA5455;
}

.pagination-danger .page-item.prev-item .page-link:hover, .pagination-danger .page-item.next-item .page-link:hover {
  background : #EA5455;
  color : #FFFFFF;
}

.nav-pill-danger .nav-item .nav-link.active {
  color : #FFFFFF;
  background-color : #EA5455 !important;
}

.progress-bar-danger {
  background-color : rgba(234, 84, 85, 0.1);
}
.progress-bar-danger .progress-bar {
  background-color : #EA5455;
}

.chip-danger {
  background-color : #EA5455 !important;
}
.chip-danger .chip-body {
  color : #FFFFFF !important;
}

.divider.divider-danger .divider-text:before, .divider.divider-danger .divider-text:after {
  border-color : #EA5455 !important;
}

input[type='checkbox'].bg-danger + .custom-control-label:before, input[type='radio'].bg-danger + .custom-control-label:before {
  background-color : #EA5455 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #EA5455 !important;
}

.vs-checkbox-danger input:checked ~ .vs-checkbox {
  border-color : #EA5455 !important;
}
.vs-checkbox-danger input:checked ~ .vs-checkbox .vs-checkbox--check {
  background-color : #EA5455 !important;
}

.vs-checkbox-danger input:active:checked + .vs-checkbox .vs-checkbox--check {
  background-color : #EA5455 !important;
}

.custom-switch-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color : #EA5455 !important;
  color : #FFFFFF;
  -webkit-transition : all 0.2s ease-out;
          transition : all 0.2s ease-out;
}

.vs-radio-danger input:checked ~ .vs-radio .vs-radio--circle {
  background : #EA5455 !important;
  box-shadow : 0 3px 12px 0 rgba(234, 84, 85, 0.4) !important;
}

.danger.darken-1 {
  color : #E73D3E !important;
}

.bg-danger.bg-darken-1 {
  background-color : #E73D3E !important;
}

.btn-danger.btn-darken-1 {
  border-color : #E42728 !important;
  background-color : #E73D3E !important;
}
.btn-danger.btn-darken-1:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-darken-1:focus, .btn-danger.btn-darken-1:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-darken-1 {
  border-color : #E73D3E !important;
  color : #E73D3E !important;
}
.btn-outline-danger.btn-outline-darken-1:hover {
  background-color : #E73D3E !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #E73D3E !important;
}

.border-danger.border-darken-1 {
  border : 1px solid #E73D3E !important;
}

.border-top-danger.border-top-darken-1 {
  border-top : 1px solid #E73D3E !important;
}

.border-bottom-danger.border-bottom-darken-1 {
  border-bottom : 1px solid #E73D3E !important;
}

.border-left-danger.border-left-darken-1 {
  border-left : 1px solid #E73D3E !important;
}

.border-right-danger.border-right-darken-1 {
  border-right : 1px solid #E73D3E !important;
}

.overlay-danger.overlay-darken-1 {
  background : #E73D3E;
  /* The Fallback */
  background : rgba(231, 61, 62, 0.6);
}

.danger.darken-2 {
  color : #E42728 !important;
}

.bg-danger.bg-darken-2 {
  background-color : #E42728 !important;
}

.btn-danger.btn-darken-2 {
  border-color : #E42728 !important;
  background-color : #E42728 !important;
}
.btn-danger.btn-darken-2:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-darken-2:focus, .btn-danger.btn-darken-2:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-darken-2 {
  border-color : #E42728 !important;
  color : #E42728 !important;
}
.btn-outline-danger.btn-outline-darken-2:hover {
  background-color : #E42728 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #E42728 !important;
}

.border-danger.border-darken-2 {
  border : 1px solid #E42728 !important;
}

.border-top-danger.border-top-darken-2 {
  border-top : 1px solid #E42728 !important;
}

.border-bottom-danger.border-bottom-darken-2 {
  border-bottom : 1px solid #E42728 !important;
}

.border-left-danger.border-left-darken-2 {
  border-left : 1px solid #E42728 !important;
}

.border-right-danger.border-right-darken-2 {
  border-right : 1px solid #E42728 !important;
}

.overlay-danger.overlay-darken-2 {
  background : #E42728;
  /* The Fallback */
  background : rgba(228, 39, 40, 0.6);
}

.danger.darken-3 {
  color : #D71A1C !important;
}

.bg-danger.bg-darken-3 {
  background-color : #D71A1C !important;
}

.btn-danger.btn-darken-3 {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-darken-3:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-darken-3:focus, .btn-danger.btn-darken-3:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-darken-3 {
  border-color : #D71A1C !important;
  color : #D71A1C !important;
}
.btn-outline-danger.btn-outline-darken-3:hover {
  background-color : #D71A1C !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #D71A1C !important;
}

.border-danger.border-darken-3 {
  border : 1px solid #D71A1C !important;
}

.border-top-danger.border-top-darken-3 {
  border-top : 1px solid #D71A1C !important;
}

.border-bottom-danger.border-bottom-darken-3 {
  border-bottom : 1px solid #D71A1C !important;
}

.border-left-danger.border-left-darken-3 {
  border-left : 1px solid #D71A1C !important;
}

.border-right-danger.border-right-darken-3 {
  border-right : 1px solid #D71A1C !important;
}

.overlay-danger.overlay-darken-3 {
  background : #D71A1C;
  /* The Fallback */
  background : rgba(215, 26, 28, 0.6);
}

.danger.darken-4 {
  color : #C01819 !important;
}

.bg-danger.bg-darken-4 {
  background-color : #C01819 !important;
}

.btn-danger.btn-darken-4 {
  border-color : #E42728 !important;
  background-color : #C01819 !important;
}
.btn-danger.btn-darken-4:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-darken-4:focus, .btn-danger.btn-darken-4:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-darken-4 {
  border-color : #C01819 !important;
  color : #C01819 !important;
}
.btn-outline-danger.btn-outline-darken-4:hover {
  background-color : #C01819 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #C01819 !important;
}

.border-danger.border-darken-4 {
  border : 1px solid #C01819 !important;
}

.border-top-danger.border-top-darken-4 {
  border-top : 1px solid #C01819 !important;
}

.border-bottom-danger.border-bottom-darken-4 {
  border-bottom : 1px solid #C01819 !important;
}

.border-left-danger.border-left-darken-4 {
  border-left : 1px solid #C01819 !important;
}

.border-right-danger.border-right-darken-4 {
  border-right : 1px solid #C01819 !important;
}

.overlay-danger.overlay-darken-4 {
  background : #C01819;
  /* The Fallback */
  background : rgba(192, 24, 25, 0.6);
}

.danger.accent-1 {
  color : #FFEEF1 !important;
}

.bg-danger.bg-accent-1 {
  background-color : #FFEEF1 !important;
}

.btn-danger.btn-accent-1 {
  border-color : #E42728 !important;
  background-color : #FFEEF1 !important;
}
.btn-danger.btn-accent-1:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-accent-1:focus, .btn-danger.btn-accent-1:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-accent-1 {
  border-color : #FFEEF1 !important;
  color : #FFEEF1 !important;
}
.btn-outline-danger.btn-outline-accent-1:hover {
  background-color : #FFEEF1 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFEEF1 !important;
}

.border-danger.border-accent-1 {
  border : 1px solid #FFEEF1 !important;
}

.border-top-danger.border-top-accent-1 {
  border-top : 1px solid #FFEEF1 !important;
}

.border-bottom-danger.border-bottom-accent-1 {
  border-bottom : 1px solid #FFEEF1 !important;
}

.border-left-danger.border-left-accent-1 {
  border-left : 1px solid #FFEEF1 !important;
}

.border-right-danger.border-right-accent-1 {
  border-right : 1px solid #FFEEF1 !important;
}

.overlay-danger.overlay-accent-1 {
  background : #FFEEF1;
  /* The Fallback */
  background : rgba(255, 238, 241, 0.6);
}

.danger.accent-2 {
  color : #FFD6DB !important;
}

.bg-danger.bg-accent-2 {
  background-color : #FFD6DB !important;
}

.btn-danger.btn-accent-2 {
  border-color : #E42728 !important;
  background-color : #FFD6DB !important;
}
.btn-danger.btn-accent-2:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-accent-2:focus, .btn-danger.btn-accent-2:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-accent-2 {
  border-color : #FFD6DB !important;
  color : #FFD6DB !important;
}
.btn-outline-danger.btn-outline-accent-2:hover {
  background-color : #FFD6DB !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFD6DB !important;
}

.border-danger.border-accent-2 {
  border : 1px solid #FFD6DB !important;
}

.border-top-danger.border-top-accent-2 {
  border-top : 1px solid #FFD6DB !important;
}

.border-bottom-danger.border-bottom-accent-2 {
  border-bottom : 1px solid #FFD6DB !important;
}

.border-left-danger.border-left-accent-2 {
  border-left : 1px solid #FFD6DB !important;
}

.border-right-danger.border-right-accent-2 {
  border-right : 1px solid #FFD6DB !important;
}

.overlay-danger.overlay-accent-2 {
  background : #FFD6DB;
  /* The Fallback */
  background : rgba(255, 214, 219, 0.6);
}

.danger.accent-3 {
  color : #FFECEE !important;
}

.bg-danger.bg-accent-3 {
  background-color : #FFECEE !important;
}

.btn-danger.btn-accent-3 {
  border-color : #E42728 !important;
  background-color : #FFECEE !important;
}
.btn-danger.btn-accent-3:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-accent-3:focus, .btn-danger.btn-accent-3:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-accent-3 {
  border-color : #FFECEE !important;
  color : #FFECEE !important;
}
.btn-outline-danger.btn-outline-accent-3:hover {
  background-color : #FFECEE !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFECEE !important;
}

.border-danger.border-accent-3 {
  border : 1px solid #FFECEE !important;
}

.border-top-danger.border-top-accent-3 {
  border-top : 1px solid #FFECEE !important;
}

.border-bottom-danger.border-bottom-accent-3 {
  border-bottom : 1px solid #FFECEE !important;
}

.border-left-danger.border-left-accent-3 {
  border-left : 1px solid #FFECEE !important;
}

.border-right-danger.border-right-accent-3 {
  border-right : 1px solid #FFECEE !important;
}

.overlay-danger.overlay-accent-3 {
  background : #FFECEE;
  /* The Fallback */
  background : rgba(255, 236, 238, 0.6);
}

.danger.accent-4 {
  color : #FFD3D7 !important;
}

.bg-danger.bg-accent-4 {
  background-color : #FFD3D7 !important;
}

.btn-danger.btn-accent-4 {
  border-color : #E42728 !important;
  background-color : #FFD3D7 !important;
}
.btn-danger.btn-accent-4:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-accent-4:focus, .btn-danger.btn-accent-4:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-accent-4 {
  border-color : #FFD3D7 !important;
  color : #FFD3D7 !important;
}
.btn-outline-danger.btn-outline-accent-4:hover {
  background-color : #FFD3D7 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #FFD3D7 !important;
}

.border-danger.border-accent-4 {
  border : 1px solid #FFD3D7 !important;
}

.border-top-danger.border-top-accent-4 {
  border-top : 1px solid #FFD3D7 !important;
}

.border-bottom-danger.border-bottom-accent-4 {
  border-bottom : 1px solid #FFD3D7 !important;
}

.border-left-danger.border-left-accent-4 {
  border-left : 1px solid #FFD3D7 !important;
}

.border-right-danger.border-right-accent-4 {
  border-right : 1px solid #FFD3D7 !important;
}

.overlay-danger.overlay-accent-4 {
  background : #FFD3D7;
  /* The Fallback */
  background : rgba(255, 211, 215, 0.6);
}

.danger.gradient-bg {
  color : #640064 !important;
}

.bg-danger.bg-gradient-bg {
  background-color : #640064 !important;
}

.btn-danger.btn-gradient-bg {
  border-color : #E42728 !important;
  background-color : #640064 !important;
}
.btn-danger.btn-gradient-bg:hover {
  border-color : #E42728 !important;
  background-color : #D71A1C !important;
}
.btn-danger.btn-gradient-bg:focus, .btn-danger.btn-gradient-bg:active {
  border-color : #D71A1C !important;
  background-color : #C01819 !important;
}

.btn-outline-danger.btn-outline-gradient-bg {
  border-color : #640064 !important;
  color : #640064 !important;
}
.btn-outline-danger.btn-outline-gradient-bg:hover {
  background-color : #640064 !important;
}

input:focus ~ .bg-danger {
  box-shadow : 0 0 0 0.075rem #FFFFFF, 0 0 0 0.21rem #640064 !important;
}

.border-danger.border-gradient-bg {
  border : 1px solid #640064 !important;
}

.border-top-danger.border-top-gradient-bg {
  border-top : 1px solid #640064 !important;
}

.border-bottom-danger.border-bottom-gradient-bg {
  border-bottom : 1px solid #640064 !important;
}

.border-left-danger.border-left-gradient-bg {
  border-left : 1px solid #640064 !important;
}

.border-right-danger.border-right-gradient-bg {
  border-right : 1px solid #640064 !important;
}

.overlay-danger.overlay-gradient-bg {
  background : #640064;
  /* The Fallback */
  background : rgba(100, 0, 100, 0.6);
}