/*=========================================================================================
	File Name: horizontal-menu.scss
	Description: A classic horizontal menu for easy navingation & support all devices.
	It support light & dark version, filpped layout, right side icons, borders menu for
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard TemplateTheme
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/*========================================================
        DARK LAYOUT
=========================================================*/
.horizontal-menu .content {
  margin-left : 0;
}
.horizontal-menu .content .content-wrapper {
  margin-top : 0 !important;
}

.horizontal-menu footer {
  position : static;
}

.horizontal-menu.navbar-floating:not(.blank-page) .app-content {
  padding-top : 80px;
}

.horizontal-menu.navbar-floating .horizontal-menu-wrapper {
  background : -webkit-linear-gradient(top, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
  background :         linear-gradient(to bottom, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
  background-repeat : repeat-x;
}
.horizontal-menu.navbar-floating .horizontal-menu-wrapper .navbar-horizontal.floating-nav {
  margin : 1.3rem 2.2rem 0;
}

.horizontal-menu.dark-layout .header-navbar {
  background : inherit !important;
}

.horizontal-menu.dark-layout .horizontal-menu-wrapper {
  background : -webkit-linear-gradient(top, rgba(37, 43, 71, 0.76) 44%, rgba(56, 53, 53, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
  background :         linear-gradient(to bottom, rgba(37, 43, 71, 0.76) 44%, rgba(56, 53, 53, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
}
.horizontal-menu.dark-layout .horizontal-menu-wrapper .header-navbar {
  background : #10163A !important;
}
.horizontal-menu.dark-layout .horizontal-menu-wrapper .header-navbar.navbar-horizontal ul#main-menu-navigation > li:hover:not(.active) > a {
  background : #262C49;
}

.horizontal-menu.semi-dark-layout .header-navbar {
  background : -webkit-linear-gradient(top, rgba(37, 43, 71, 0.76) 44%, rgba(56, 53, 53, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
  background :         linear-gradient(to bottom, rgba(37, 43, 71, 0.76) 44%, rgba(56, 53, 53, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
}

.horizontal-menu.semi-dark-layout .horizontal-menu-wrapper .header-navbar {
  background : #10163A !important;
}
.horizontal-menu.semi-dark-layout .horizontal-menu-wrapper .header-navbar.navbar-horizontal ul#main-menu-navigation > li:hover > a {
  background : #262C49;
}
.horizontal-menu.semi-dark-layout .horizontal-menu-wrapper .header-navbar i, .horizontal-menu.semi-dark-layout .horizontal-menu-wrapper .header-navbar span, .horizontal-menu.semi-dark-layout .horizontal-menu-wrapper .header-navbar .nav-link {
  color : #C2C6DC;
}

.horizontal-menu.semi-dark-layout .horizontal-menu-wrapper .dropdown-menu {
  background-color : #262C49;
}

.horizontal-menu.navbar-sticky .app-content {
  padding-top : 8.5rem;
}

.horizontal-menu.navbar-sticky .header-navbar {
  background-color : #F8F8F8;
}

.horizontal-menu.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
  left : 0;
  top : 62px;
  background-color : #FFFFFF;
}

.horizontal-menu.navbar-static .app-content {
  padding-top : 2.5rem;
}

.horizontal-menu.navbar-static .header-navbar {
  background-color : #F8F8F8;
}
.horizontal-menu.navbar-static .header-navbar .navbar-wrapper {
  width : 100%;
}

.horizontal-menu.navbar-static .horizontal-menu-wrapper {
  position : relative;
}
.horizontal-menu.navbar-static .horizontal-menu-wrapper .navbar-horizontal.header-navbar {
  background : #FFFFFF;
}
.horizontal-menu.navbar-static .horizontal-menu-wrapper .navbar-horizontal.header-navbar.navbar-static-top {
  position : static;
  background : #FFFFFF;
  left : 0;
}

.horizontal-menu .horizontal-menu-wrapper {
  position : fixed;
  top : 62px;
  z-index : 990;
  width : 100%;
  height : 99px;
}
.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-container {
  padding-left : 1rem;
  padding-right : 1rem;
  width : 100%;
}
.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-header {
  display : none;
}

.horizontal-menu .header-navbar {
  background : #FFFFFF;
  z-index : 999 !important;
  line-height : 1;
  min-height : auto;
}
.horizontal-menu .header-navbar.navbar-horizontal.floating-nav {
  width : calc(100vw - (100vw - 100%) - calc(2.2rem * 2));
  background : #FFFFFF;
}
.horizontal-menu .header-navbar .navbar-container {
  padding-left : 2.2rem;
  padding-right : calc(2.2rem - 1rem);
  border-radius : 0.5rem;
}
.horizontal-menu .header-navbar.navbar-fixed {
  position : fixed;
  width : 100%;
}
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  position : absolute;
  left : 50%;
  margin-left : -65px;
  padding : 0;
  z-index : 1000;
}
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-box-align : center;
  -webkit-align-items : center;
  -ms-flex-align : center;
          align-items : center;
  margin-right : 0;
}
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo {
  background : url('../../../../images/logo/vuexy-logo.png') no-repeat;
  background-position : -65px -54px;
  height : 24px;
  width : 35px;
}
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-text {
  color : #FFFFFF;
  padding-left : 1rem;
  letter-spacing : 0.01rem;
  font-size : 1.57rem;
}
.horizontal-menu .header-navbar.navbar-horizontal .nav-link.dropdown-toggle::after {
  left : 0.4rem;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu::before {
  display : none;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu {
  min-width : 215px;
  border : none;
  margin-top : 0;
  min-height : 52px;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .disabled {
  pointer-events : none !important;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .disabled a {
  color : #B8C2CC;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-toggle::after {
  left : auto;
  position : absolute;
  top : 50%;
  margin-top : -7px;
  right : 1rem;
  content : '\e844' !important;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-item {
  font-size : 1rem;
  padding : 0.965rem 1.428rem;
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-box-align : center;
  -webkit-align-items : center;
  -ms-flex-align : center;
          align-items : center;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu {
  position : relative;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu.openLeft .dropdown-menu {
  left : auto;
  right : 100%;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu.show {
  background : #F8F8F8;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu > .dropdown-menu {
  position : absolute;
  top : 0 !important;
  left : 100% !important;
}
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu > .dropdown-menu i {
  font-size : 0.75rem !important;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li {
  padding-top : 0.965rem;
  padding-bottom : 0.965rem;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu {
  margin-top : 0.75rem;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a {
  -webkit-transition : padding 0.35s ease 0s !important;
          transition : padding 0.35s ease 0s !important;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a:hover {
  padding-left : 25px;
  -webkit-transition : padding 0.35s ease 0s !important;
          transition : padding 0.35s ease 0s !important;
  background-color : transparent;
  color : #626262;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu .active > a {
  background : #F8F8F8;
  color : #7367F0;
  font-weight : 500;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu .open.active > a {
  color : #626262;
  font-weight : normal;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li i {
  font-size : 1.11rem;
  margin-right : 0.75rem;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
  padding : 0.75rem 1.25rem 0.75rem 1.25rem;
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  margin-right : 0.5rem;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li:hover > a {
  background : #F8F8F8;
  border-radius : 4px;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li.active > a {
  background : -webkit-linear-gradient(332deg, #7367F0, rgba(115, 103, 240, 0.7));
  background :         linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7));
  box-shadow : 0 0 6px 1px rgba(115, 103, 240, 0.6);
  color : #FFFFFF;
  border-radius : 4px;
}

.horizontal-menu.menu-collapsed #main-menu-navigation .nav-item > a span {
  display : none;
}

.horizontal-menu .navigation-header {
  font-family : inherit;
  color : #929292;
  padding : 8px 20px;
  font-size : 1rem;
  text-transform : uppercase;
}

.horizontal-menu .navbar-dark .nav-item.active > a {
  border-bottom : 2px solid #7367F0;
  background-color : #313C50;
}

.horizontal-menu .content.app-content .content-area-wrapper {
  margin-top : 2.2rem;
}
.horizontal-menu .content.app-content .content-area-wrapper .content-wrapper {
  height : calc(100vh - 16rem);
  height : calc(var(--vh, 1vh) * 100 - 16rem);
}

.horizontal-menu.chat-application .sidebar-content, .horizontal-menu.email-application .content-area-wrapper .sidebar .email-app-sidebar, .horizontal-menu.todo-application .content-area-wrapper .sidebar .todo-sidebar {
  height : calc(100vh - 16rem);
  height : calc(var(--vh, 1vh) * 100 - 16rem);
}

.horizontal-menu.todo-application .content-area-wrapper .content-right .todo-task-list {
  height : calc(100vh - 19.5rem);
  height : calc(var(--vh, 1vh) * 100 - 19.5rem);
}

.horizontal-menu.email-application .app-content .content-area-wrapper .email-user-list {
  height : calc(100vh - 23.3rem);
  height : calc(var(--vh, 1vh) * 100 - 23.3rem);
}

.horizontal-menu.chat-application .user-profile-sidebar {
  height : calc(100vh - 16.1rem);
  height : calc(var(--vh, 1vh) * 100 - 16.1rem);
  bottom : 4.1rem;
}

.horizontal-menu.chat-application .chat-profile-sidebar {
  height : calc(100vh - 16rem);
  height : calc(var(--vh, 1vh) * 100 - 16rem);
}

.horizontal-menu.chat-application .chat-app-window .user-chats {
  height : calc(100vh - 26.5rem);
  height : calc(var(--vh, 1vh) * 100 - 26.5rem);
}

@media (max-width: 1199.98px) {
  body.horizontal-layout.horizontal-menu .horizontal-menu-wrapper .header-navbar {
    display : none;
  }
  body.horizontal-layout .header-navbar {
    background : #FFFFFF;
  }
  body.horizontal-layout .content .content-wrapper {
    margin-top : 5rem;
  }
}

/*=========================================================================================
    File Name: vertical-overlay-menu.scss
    Description: A overlay style vertical menu with show and hide support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/*========================================================
        DARK LAYOUT
=========================================================*/
.vertical-overlay-menu .content {
  margin-left : 0;
}

.vertical-overlay-menu .navbar .navbar-header {
  float : left;
  width : 260px;
}

.vertical-overlay-menu .navbar.header-navbar.floating-nav {
  width : calc(100vw - (100vw - 100%) - calc(2.2rem * 2));
}

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity : 0;
  -webkit-transform : translate3d(0, 0, 0);
          transform : translate3d(0, 0, 0);
  -webkit-transition : width 0.25s,opacity 0.25s,-webkit-transform 0.25s;
          transition : width 0.25s,opacity 0.25s,-webkit-transform 0.25s;
          transition : width 0.25s,opacity 0.25s,transform 0.25s;
          transition : width 0.25s,opacity 0.25s,transform 0.25s,-webkit-transform 0.25s;
  width : 260px;
  left : -260px;
}
.vertical-overlay-menu .main-menu .navigation .navigation-header .icon-minus {
  display : none;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i {
  margin-right : 14px;
  float : left;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i:before {
  -webkit-transition : 200ms ease all;
          transition : 200ms ease all;
  font-size : 1.429rem;
}
.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
  content : '\f105';
  font-family : 'FontAwesome';
  font-size : 1rem;
  display : inline-block;
  position : absolute;
  right : 25px;
  top : 10px;
  -webkit-transform : rotate(0deg);
      -ms-transform : rotate(0deg);
          transform : rotate(0deg);
  transition : -webkit-transform 0.2s ease-in-out;
}
.vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after {
  -webkit-transform : rotate(90deg);
      -ms-transform : rotate(90deg);
          transform : rotate(90deg);
}
.vertical-overlay-menu .main-menu .navigation li a i {
  font-size : 1.1rem;
}
.vertical-overlay-menu .main-menu .main-menu-footer {
  bottom : 55px;
}
.vertical-overlay-menu .main-menu .main-menu-footer {
  width : 260px;
}

.vertical-overlay-menu.menu-open .main-menu {
  opacity : 1;
  -webkit-transform : translate3d(260px, 0, 0);
          transform : translate3d(260px, 0, 0);
  -webkit-transition : width 0.25s,opacity 0.25s,-webkit-transform 0.25s;
          transition : width 0.25s,opacity 0.25s,-webkit-transform 0.25s;
          transition : width 0.25s,opacity 0.25s,transform 0.25s;
          transition : width 0.25s,opacity 0.25s,transform 0.25s,-webkit-transform 0.25s;
}

.vertical-overlay-menu.menu-flipped .main-menu {
  right : -260px;
  left : inherit;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-container {
  margin : 0;
  margin-right : 260px;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-header {
  float : right;
}

.vertical-overlay-menu.menu-flipped.menu-open .main-menu {
  -webkit-transform : translate3d(-260px, 0, 0);
          transform : translate3d(-260px, 0, 0);
}

@media (max-width: 991.98px) {
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom : 0;
  }
}